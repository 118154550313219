import React, { useState, useEffect } from 'react';
import '../../../assets/styles/MutualFunds.css'; // Your custom styles
import ITCS1 from '../../../assets/images/ITCS1.webp';
import ITCS2 from '../../../assets/images/ITCS2.jpg';
import ITCS3 from '../../../assets/images/ITCS3.jpeg';
import ITCS4 from '../../../assets/images/ITCS4.avif';




const MutualFunds = () => {
  const slides = [
    { image: ITCS1, text: "Mutual Funds: The Path to a Secure Financial Future." },
    { image: ITCS2, text: "Building Wealth, One Investment at a Time." },
    { image: ITCS3, text: "Invest Today, Secure Tomorrow." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  const mfservices = [
    {
      heading: "Expert Portfolio Management:",
      items: [
        "Active Monitoring: Our portfolio managers track market trends and fund performance to keep your investments aligned with your goals.",
        "Maximized Returns: By leveraging expert analysis, we help you achieve the best possible returns with minimized risks.",
        "Focus on You: Leave the complexities to us and enjoy peace of mind while we handle your portfolio.",
      ],
    },
    {
      heading: "Customized Solutions:",
      items: [
        "Tailored Strategies: We craft personalized investment plans based on your risk tolerance, goals, and financial timeline.",
        "Flexibility: Adjust your strategy as your financial needs or market conditions evolve.",
        "Targeted Outcomes: Whether seeking growth or stability, our solutions fit your preferences perfectly.",
      ],
    },
    {
      heading: "Transparency and Trust:",
      items: [
        "Real-Time Updates: Stay informed with instant access to portfolio performance and analytics.",
        "Clear Fee Structures: No hidden costs—just straightforward, honest pricing.",
        "Client-First Approach: Our transparent processes ensure long-term trust and confidence.",
      ],
    },
    {
      heading: "Wide Range of Fund Options:",
      items: [
        "Diversified Choices: Access equity, debt, hybrid, index funds, and sector-specific options.",
        "Adaptability: Build a balanced portfolio that evolves with changing market conditions.",
        "Comprehensive Offerings: Opportunities for every investment objective and risk profile.",
      ],
    },
    {
      heading: "Tax Benefits:",
      items: [
        "ELSS Funds: Reduce taxable income under Section 80C with high-growth investment options.",
        "Guidance on Benefits: Advisors ensure you maximize your tax savings effectively.",
        "Dual Advantage: Achieve financial growth while optimizing tax liabilities.",
      ],
    },
    {
      heading: "Convenient Investment Process:",
      items: [
        "Ease of Use: A seamless online platform simplifies investing for everyone.",
        "Flexible Options: Choose between SIPs or lump-sum investments to match your convenience.",
        "Automated Systems: Enjoy reminders and automatic contributions for consistent growth.",
      ],
    },
    {
      heading: "Dedicated Support and Guidance:",
      items: [
        "Continuous Engagement: Regular portfolio reviews and timely advice keep you on track.",
        "Expert Assistance: Advisors are readily available to address questions or concerns.",
        "Proactive Support: We’re with you at every step, ensuring your investment journey is smooth and rewarding.",
      ],
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Show or hide the selected answer
  };

  const faqs = [
    {
      question: "What is Carrier Launchers' approach to financial success?",
      answer:
        "Carrier Launchers focuses on a client-centric strategy, continuous support, and leveraging technological advantages to help clients achieve their financial goals.",
    },
    {
      question: "Why should I choose Carrier Launchers?",
      answer:
        "We offer unmatched expertise, global reach combined with local insights, and a secure, hassle-free investment experience.",
    },
    {
      question: "What kind of support can I expect?",
      answer:
        "You will receive continuous support from our dedicated advisors, who are always available to address your concerns and guide your investment journey.",
    },
    {
      question: "How secure is the investment process?",
      answer:
        "We employ bank-grade security measures to ensure your investments and data are protected at all times.",
    },
    {
      question: "What is the minimum investment amount?",
      answer: "Mutual funds pool money from multiple investors to invest in a diversified portfolio of stocks, bonds, or other assets, managed by professional fund managers. This allows individual investors to access a wide range of securities, which would otherwise be hard to afford or manage on their own.",
    },
    {
      question: "What are mutual funds?",
      answer:
        "Carrier Launchers focuses on a client-centric strategy, continuous support, and leveraging technological advantages to help clients achieve their financial goals.",
    },
    {
      question: "How much do I need to start investing?",
      answer:
        "You can start with as little as [minimum amount] through systematic investment plans (SIPs) or one-time investments. SIPs allow you to invest small amounts regularly, making it an affordable option for new investors.",
    },
    {
      question: "Are mutual funds safe?",
      answer:
        "All investments carry risks, including mutual funds. However, mutual funds offer diversification, which spreads the risk across various assets. We assess your risk tolerance and guide you toward funds that match your financial goals and comfort level with risk.",
    },
    {
      question: "How do I choose the right mutual fund?",
      answer:
        "Choosing the right mutual fund depends on factors like your financial goals, risk tolerance, and investment horizon. Our experts provide personalized recommendations based on these factors, helping you build a portfolio that suits your needs.",
    },
    {
      question: "Can I invest in mutual funds through SIPs?",
      answer: "Yes, SIPs (Systematic Investment Plans) are an excellent way to invest in mutual funds. SIPs allow you to invest a fixed amount regularly, which helps to average the purchase cost over time and reduce the impact of market volatility.",
    },
    {
      question: "What is the difference between equity and debt mutual funds?",
      answer: "Equity mutual funds invest primarily in stocks, offering higher growth potential but also higher risk. Debt mutual funds invest in bonds and other fixed-income securities, providing more stability and lower risk, but with potentially lower returns.",
    },
    {
      question: "What are the tax implications of investing in mutual funds?",
      answer: "Mutual funds are subject to capital gains tax. Long-term capital gains (LTCG) from equity funds are taxed at a lower rate, while short-term gains are taxed at a higher rate. Debt funds have different tax rules. Additionally, tax-saving mutual funds (ELSS) offer benefits under Section 80C of the Income Tax Act.",
    },
    {
      question: "How are mutual funds taxed?",
      answer: "Mutual fund returns are taxed based on the type of investment:Equity Funds: Long-term capital gains (holding period of more than 1 year) are taxed at 10%, while short-term capital gains are taxed at 15%. Debt Funds: Long-term capital gains (holding period of more than 3 years) are taxed at 20% with indexation benefits, while short-term capital gains are taxed as per your income tax slab.",
    },
    {
      question: "Can I withdraw my money anytime from a mutual fund?",
      answer: "Yes, you can redeem your mutual fund investments at any time. The process is simple and the funds are typically credited to your account within 2-3 business days. However, keep in mind that some mutual funds may have exit loads if redeemed before a specific period.",
    },
    {
      question: "What are exit loads in mutual funds?",
      answer: "Exit loads are fees charged when you redeem your mutual fund units before a certain period, usually 1 to 3 years. The exit load is designed to discourage early withdrawals and to ensure long-term stability of the fund. These charges are typically a small percentage of the amount being redeemed.",
    },
  ];

  return (
    <>
      {/* Carousel Section */}
      <section className="carousel-section">
        <div className="carousel-container">
          <div
            className="carousel-slide zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="text-overlay">
              <p className="carousel-text">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="mf-section">
          <div className="containermf">
            <div className="mf-content">
              <h2 className="mf-heading">About Our Mutual Fund Services</h2>
              <p className="mf-description">
                Mutual funds offer a convenient way to invest and grow your wealth by pooling money from multiple investors to create a diversified portfolio managed by experts. At Carrier Launchers, we simplify this process, ensuring that investing in mutual funds is not just accessible but also rewarding. Whether you’re saving for your child’s education, purchasing your dream home, or planning for a secure retirement, our mutual fund solutions are tailored to meet your specific needs.
              </p>
              <p className="mf-description">
                We provide expert guidance to help you select the right funds based on your financial goals, risk tolerance, and investment horizon. By investing in mutual funds with us, you not only benefit from professional fund management but also achieve diversification, reducing risks while optimizing returns. No matter where you are in your financial journey, our services are designed to help you grow your wealth and realize your dreams.
              </p>
              <div className="mf-button">
                <a href="/" className="rdm1">Learn More--</a>
              </div>
            </div>
            <div className="mf-image">
              <img src={ITCS4} alt="Mission" />
            </div>
          </div>
        </div>
      </section>
      <section>
      <div className="content-containers">
        <h2 className="main-headers">Why Invest in Mutual Funds with Us?</h2>
        {mfservices.map((service, index) => (
          <div key={index} className="service-sections">
            <h3 className="sub-headers">{service.heading}</h3>
            <ul className="content-lists">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      </section>
      <hr />
      <section className="sect bg-f2f2f2 py-5">
        <div className="containera">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect1-title1">
                <span className="text-danger">Our  </span>Approach
              </h2>
              <div
                className="underlines1 mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              <p>At <span className="text-danger">Carrier Launchers </span> , we are committed to helping you achieve financial success through a client-focused, innovative, and professional approach. Here's how we make it happen:</p>
            </div>
          </div>

          {/* Features */}
          <div className="row text-center mt-4">
            {/* Feature 1 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-user-tie text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Client-Centric Strategy</h5>
                <p className="feature-text2">
                Your goals are our top priority. We design personalized investment plans tailored to your unique financial aspirations, risk tolerance, and timeline.
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-briefcase text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Continuous Support</h5>
                <p className="feature-text2">
                Our dedicated advisors and robust support system are always available to address your concerns, provide insights, and guide your investment journey.
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-globe text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Technological Advantage</h5>
                <p className="feature-text2">
                Enjoy seamless investment management with our user-friendly online platform, designed to provide real-time updates, analytics, and convenience at your fingertips.
                </p>
              </div>
            </div>
            <div className="containera">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect1-title1"> Why
                <span className="text-danger"> Choose </span>  Us?
              </h2>
              <div
                className="underlines1 mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              
            </div>
          </div>
           </div>
            {/* Feature 4 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-clock text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Unmatched Expertise</h5>
                <p className="feature-text2">
                With years of industry experience and a proven track record, our team offers in-depth market knowledge and investment strategies that deliver results.
                </p>
              </div>
            </div>

            {/* Feature 5 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-chart-line text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Global Reach, Local Insights</h5>
                <p className="feature-text2">
                We combine comprehensive international market expertise with an understanding of local dynamics, ensuring your investments are strategically placed for maximum growth.
                </p>
              </div>
            </div>

            {/* Feature 6 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box2 p-4">
                <i
                  className="fas2 fa-handshake text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title2">Secure & Hassle-Free</h5>
                <p className="feature-text2">
                Experience peace of mind with bank-grade security measures and a simple, intuitive interface that makes managing your investments easy and stress-free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="scrollable-faq-section bg-light py-5">
      <div className="container">
        {/* Section Title */}
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="sect1-title1">
              <span className="text-danger">Frequently Asked </span> Questions
            </h2>
            <div
              className="underlines1 mx-auto mb-4"
              style={{ width: "150px", height: "4px", backgroundColor: "#e74c3c" }}
            ></div>
          </div>
        </div>

        {/* Scrollable FAQ Box */}
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="faq-scroll-box">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item mb-3">
                  <div
                    className="faq-question d-flex justify-content-between align-items-center bg-white p-3 border rounded"
                    onClick={() => toggleFAQ(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <h6 className="mb-0">{faq.question}</h6>
                    <span>
                      {activeIndex === index ? (
                        <i className="bi bi-dash-circle-fill text-danger"></i>
                      ) : (
                        <i className="bi bi-plus-circle-fill text-danger"></i>
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div className="faq-answer bg-light p-3 border rounded mt-2">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
      <section className="appointment">
        <div className="container">
          <div className="fact">
            <h2 className="fact-heading">Ready to Build Your Wealth?</h2>
            <p className="fact-description">
            Contact us today and let Carrier Launchers be your partner in financial growth!
            </p>
            <a href="/" className="btn-appointment">Get Started Now</a> {/* Action Button */}
          </div>
        </div>
      </section>
    </>
  );
};

export default MutualFunds;
