import React, { useState, useEffect } from 'react';
import '../../../assets/styles/IE.css';
import HC1 from '../../../assets/images/HC1.jpg'
import HC2 from '../../../assets/images/HC2.jpg';
import HC3 from '../../../assets/images/HC3.jpg';
import HC4 from '../../../assets/images/HC4.jpg';

const IE = () => {
  const slides = [
    { image: HC1, text: "Transform Your Financial Journey with Expert-Led Education." },
    { image: HC2, text: "Education That Drives Smart, Successful Investments." },
    { image: HC3, text: "Smart Investing Starts with Smart Education." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  const services = [
    {
      heading: "Expert-Led Education:",
      items: [
        "Our team of seasoned financial professionals, analysts, and experienced educators are committed to delivering high-quality investment education that is both insightful and easy to understand.",
        "Whether you're just embarking on your investment journey or seeking to refine your existing knowledge, our experts are here to guide you through each step.",
        "With years of industry experience, they provide invaluable insights and actionable strategies to help you succeed in the complex world of investing.",
      ],
    },
    {
      heading: "Tailored Learning Paths:",
      items: [
        "At Carrier Launchers, we understand that every learner has unique financial goals. That's why we offer personalized learning experiences designed to meet your specific needs, whether you're a beginner or an advanced investor.",
        "Our courses are flexible, allowing you to progress at your own pace while focusing on the areas that are most relevant to your investment objectives. We ensure that your learning path is aligned with your long-term financial success.",
        
      ],
    },
    {
      heading: "Comprehensive Curriculum",
      items: [ 
        "Stock Market Basics: Understanding the fundamentals of stocks, how they work, and how to make informed investment decisions.",
        "Bonds & Fixed Income Investments: Learning about various types of bonds and how they can help balance risk in your investment portfolio.",
        "Real Estate Investing: Strategies for investing in property and building wealth through real estate.",
        "Mutual Funds & ETFs: Exploring the benefits of diversification and passive investing through funds and exchange-traded funds.",
        "Cryptocurrency & Blockchain: Gaining insights into the digital asset landscape, including how to invest in cryptocurrencies and understand blockchain technology.",
        "Retirement Planning: Understanding strategies to secure your financial future and ensure you’re well-prepared for retirement.",
        "Tax Optimization Strategies: Learning how to minimize tax liability and optimize returns using various tax-advantageous investment strategies.",
        "Advanced Technical and Fundamental Analysis: Mastering the tools used by expert investors to analyze stocks and financial markets to make data-driven decisions.",
      ],
    },
    {
      heading: "Interactive Learning Tools:",
      items: [
        "To make your investment education experience as engaging as possible, we use a mix of dynamic learning tools that enhance your understanding. These include interactive webinars, live Q&A sessions with experts, and simulated investment platforms where you can practice real-world scenarios. This blend of practical exercises and theoretical learning ensures you not only gain knowledge but also build confidence in applying it to your investments.",
      ],
    },
    {
      heading: "Real-World Insights:",
      items: [
        "Stay ahead of the curve with the most up-to-date insights on market movements, trends, and economic conditions. We continuously update our content to reflect the latest developments in the financial world, ensuring you have access to current, relevant information. With this real-time approach, you can confidently apply your knowledge to the market and make decisions that are both timely and informed.",
      ],
    },
  ];


  return (
    <>
       {/* Carousel Section */}
       <section className="carousl-section3">
        <div className="carousl-container3">
          <div
            className="carousel-slide4 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay3">
              <p className="carousel-txt3">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision4-section">
          <div className="containerq">
            <div className="vision4-content">
              <h2 className="vision4-heading">Our Vision</h2>
              <p className="vision4-description">
              At Carrier Launchers, our vision is to become a leading global provider of investment education, empowering individuals and businesses to achieve financial freedom through knowledge and informed decision-making. We strive to create a world where everyone, regardless of background or experience, has access to the tools, resources, and education needed to make smart, impactful investments. We aim to inspire confidence, foster financial literacy, and support long-term wealth creation by delivering high-quality, practical education that adapts to the ever-changing financial landscape. Through innovative learning experiences and expert guidance, we are committed to shaping a future where financial success is within reach for all.
              </p>
              <a href="/" className="readmore4">
                    read more --
                  </a>
            </div>
            <div className="vision4-image">
              <img src={HC4} alt="Vission4" />
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="custom-section custom-bg-f2f2f2 custom-py-5">
        <div className="custom-bg1-image3">
          <div className="custom-content2">
            <h1>Our Investment Education Services</h1>
            <p>
            At Carrier Launchers, we offer comprehensive learning paths designed for investors at every stage of their journey, ensuring you gain the knowledge and skills needed to make informed decisions.
            </p>
          </div>
        </div>
        <div className="custom-cont">
          <div className="custom-row">
          </div>
          <div className="custom-row">
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Comprehensive Learning Paths for All Levels</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We believe in catering to every stage of an investor's journey. Whether you're just beginning or are a seasoned investor, our courses and programs are designed to meet your specific needs. From foundational concepts like understanding stocks and bonds to advanced strategies in real estate and cryptocurrencies, we provide a structured learning path that ensures gradual and consistent growth in your investment knowledge.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Expert-Led Guidance and Insights</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our team of experienced educators, financial analysts, and industry professionals are here to share their deep expertise with you. Learn from professionals who have worked in the field and have practical, real-world experience. Our goal is to help you not only understand investment principles but also to apply them effectively to your personal financial goals.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Practical, Hands-On Learning</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Education is most effective when you can apply what you've learned. Our courses go beyond theory by offering real-life case studies, simulated investment platforms, and interactive tools to help you gain practical experience. You’ll be able to practice making decisions and analyzing investments in a risk-free environment, so you're ready when it's time to invest your own money.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Tailored Solutions to Meet Your Goals</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Everyone has different financial objectives, and we understand that a one-size-fits-all approach doesn’t work. That’s why our programs allow you to focus on specific areas that matter most to you—whether it’s saving for retirement, growing wealth, or diversifying your portfolio. Our personalized coaching and learning paths ensure that the education you receive aligns with your financial goals.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Up-to-Date Information on Market Trends</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  The investment landscape is constantly evolving. To ensure you’re making informed decisions, our content is regularly updated to reflect the latest market trends, investment products, and economic conditions. Whether it’s changes in tax laws, market corrections, or new investment opportunities like cryptocurrency, we make sure you have the latest information at your fingertips.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Build a Strong Foundation in Financial Literacy</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Investment education isn’t just about stocks and bonds—it’s about understanding how money works and how to make it work for you. We focus on building a strong foundation in financial literacy so you can make informed decisions not only in investing but in other areas like budgeting, saving, and tax planning. This holistic approach ensures you’re financially well-rounded.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Interactive Webinars and Q&A Sessions</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Engagement is key to understanding complex investment concepts. That’s why we offer live webinars and Q&A sessions where you can interact with experts and fellow learners. These interactive sessions allow you to clarify doubts, ask questions, and dive deeper into specific topics in real-time. It's an invaluable opportunity to learn from both your instructors and peers.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Continuous Support and Mentorship</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Learning doesn’t stop when a course ends. We provide continuous support through mentorship programs and access to a community of learners. Our mentorship ensures that you’re not alone on your investment journey. Whether you need advice on a specific investment or guidance on refining your strategy, our experts are here to help.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Flexible Learning Options for Busy Schedules</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We understand that time is a precious commodity. That's why we offer flexible learning options that fit into your busy lifestyle. With self-paced online courses, on-demand content, and live sessions at convenient times, you can learn at your own pace and on your own schedule, making it easier to balance your investment education with work and other commitments.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            <ul className="content-list">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    <hr/>
    <section className="appointmentI">
        <div className="containerI">
          <div className="factI">
            <h2 className="fact-headingI">Get In Touch</h2>
            <p className="fact-descriptionI">
            At Carrier Launchers, we’re committed to helping you achieve your financial goals through expert-led investment education. Whether you’re just starting your investment journey or looking to refine your strategies, our team is here to support you every step of the way.

            </p>
            <a href="/" className="btn-appointmentI">Get in Touch Today</a> {/* Action Button */}
          </div>
        </div>
      </section>
    </>
  )
}
export default IE;
