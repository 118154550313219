import React from 'react';
import './Footer.css'; // Import the CSS file for styling the footer
import logo from '../assets/images/Logo1.png'; // Path to your logo
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'; // Importing icons

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          {/* Logo and About Section */}
          <div className="footer-logo-container">
            <img src={logo} alt="Company Logo" className="footer-logo" />
            <div className="about-us">
              <h5>Carrier Launchers</h5>
              <p>
              At Carrier Launchers, we specialize in providing comprehensive services for investors in India's dynamic financial market. Our expertise spans across Share Market Trading, Mutual Funds, Futures & Options (F&O), and the latest IPO trends, empowering individuals and businesses to make informed, strategic investment decisions. Additionally, we offer Financial Consulting and Customer Support services, ensuring a holistic approach to financial growth and personal wealth management.
              </p>
            </div>
          </div>

          {/* Services Section */}
          <div className="footer-services">
            <h5>Services</h5>
            <ul className="list-unstyled">
              <li><a href="/it-services/software-development" className="footer-link">Financial Market Services in India</a></li>
              <li><a href="/non-it-services/financial-consulting" className="footer-link">Consulting Services & Support</a></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="footer-contact">
            <h5>Contact</h5>
            <ul className="list-unstyled">
              <li><FaMapMarkerAlt className="contact-icon" /> 
                <a href="/" className="footer-link">Sector 63, Noida, Gautam Buddh Nagar, New Delhi, India 201301</a>
              </li>
              <li><FaEnvelope className="contact-icon" /> 
                <a href="mailto:rishi@carrierlaunchers.com" className="footer-link">rishi@carrierlaunchers.com</a>
              </li>
              <li><FaPhoneAlt className="contact-icon" /> 
                <a href="tel:+1234567890" className="footer-link">+91 70069 36194</a>
              </li>
            </ul>
          </div>
        </div>
   
        {/* Social Media Icons */}
        <div className="footer-social-media">
          <h5>Follow Us</h5>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61557244721671" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/company/102868195/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-icon" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
          </div>
        </div>
        <hr/>
        {/* Footer Bottom */}
        <div className="text-center py-3 footer-bottom">
          <p>&copy; {new Date().getFullYear()} Carrier Launchers. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
