import React, { useState } from 'react';
import "../../assets/styles/contact.css";
import CT from "../../assets/images/contact-png.png";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaMobileAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import emailjs from 'emailjs-com'; // Import EmailJS

const Contact = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // Handle form field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Prepare the data to send to EmailJS
    const emailData = {
      fullName: formData.fullName, // Combining first and last name
      regarding: formData.regarding,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    // Send the form data to EmailJS
    emailjs
      .send(
        "service_p0ayztq", // Replace with your EmailJS service ID
        "template_1de7so8", // Replace with your EmailJS template ID
        emailData, // Directly pass the data
        "jtpwxCcbI-QI6Izqa" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          alert("Message sent! We’ll get back to you soon.");
          console.log(result.text); // Log result for debugging
        },
        (error) => {
          alert("Failed to send message. Please try again!");
          console.error(error.text); // Log error for debugging
        }
      );
  }

  return (
    <section className="contact-section">
      <div className="contact-bg">
        <h2>Contact Us</h2>
        <div className="line">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="contact-body">
        <div className="contact-info">
          <div>
            <span><FaMobileAlt /></span>
            <span>Phone No.</span>
            <span className="text">+91 70069 36194</span>
          </div>
          <div>
            <span><FaEnvelopeOpen /></span>
            <span>E-mail</span>
            <span className="text">rishi@carrierlaunchers.com</span>
          </div>
          <div>
            <span><FaMapMarkerAlt /></span>
            <span>Address</span>
            <span className="text">Sector 63, Noida, Gautam Buddh Nagar, New Delhi, India 201301</span>
          </div>
          <div>
            <span><FaClock /></span>
            <span>Opening Hours</span>
            <span className="text">Monday - Friday (9:00 AM to 5:00 PM)</span>
          </div>
        </div>

        <div className="contact-form">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Full Name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
          />
           <select
        className="form-control"
        name="regarding"
        value={formData.regarding}
        onChange={handleChange}
      >
        <option value="">How We Support You</option> {/* Default placeholder option */}
        <option value="Share Market">Share Market</option>
        <option value="Mutual Funds">Mutual Funds</option>
        <option value="Futures & Options">Futures & Options</option>
        <option value="Stock Market News">Stock Market News</option>
        <option value="IPOs">IPOs</option>
      </select>
        </div>
        <div>
          <input
            type="email"
            className="form-control"
            placeholder="E-mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <textarea
          rows="5"
          placeholder="Tell us about yourself"
          className="form-control"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <input type="submit" className="send-btn" value="Send Message" />
      </form>

      <div>
        <img src={CT} alt="Contact" />
      </div>
    </div>
    </div>

      <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28017.34712464629!2d77.34964929923983!3d28.62471487285631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff135b3084b%3A0x19ccb4e95c69306d!2sSector%2063%2C%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1733684988957!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Company Location"
        ></iframe>
      </div>

      <div className="contact-footer">
        <h3>Follow Us</h3>
        <div className="social-links">
          <a href="/" aria-label="Facebook" className="social-link">
            <FaFacebookF />
          </a>
          <a href="/" aria-label="Twitter" className="social-link">
            <FaTwitter />
          </a>
          <a href="/" aria-label="Instagram" className="social-link">
            <FaInstagram />
          </a>
          <a href="/" aria-label="LinkedIn" className="social-link">
            <FaLinkedin />
          </a>
          <a href="/" aria-label="YouTube" className="social-link">
            <FaYoutube />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
