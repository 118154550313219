import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/images/logo.png';

const Navbar = () => {
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showITSubDropdown, setShowITSubDropdown] = useState(false);
  const [showNonITSubDropdown, setShowNonITSubDropdown] = useState(false);
  const [navbarActive, setNavbarActive] = useState(false);

  const toggleNavbar = () => {
    setNavbarActive(!navbarActive);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary" id="myNavbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <div className="center">
              <img src={logo} alt="Carrier Launchers Logo" className="logo" />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={navbarActive ? 'true' : 'false'}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarActive ? 'active' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/about">About Us</Link>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => setShowServicesDropdown(true)}
                onMouseLeave={() => setShowServicesDropdown(false)}
              >
                <Link className="nav-link">Our Services</Link>
                {showServicesDropdown && (
                  <ul className="dropdown-content">
                    <li
                      className="sub-dropdown"
                      onMouseEnter={() => setShowITSubDropdown(true)}
                      onMouseLeave={() => setShowITSubDropdown(false)}
                    >
                      <Link className="nav-link" to="#">Financial Market Services in India</Link>
                      {showITSubDropdown && (
                        <ul className="sub-dropdown-content">
                          <li><Link to="/Financial-marketing/share-market-services" className="dropdown-item">Share Market Services</Link></li>
                          <li><Link to="/Financial-marketing/mutual-funds" className="dropdown-item">Mutual Fund Services</Link></li>
                          <li><Link to="/Financial-marketing/fo" className="dropdown-item">F&O (Futures and Options) Services</Link></li>
                          <li><Link to="/Financial-marketing/ipo" className="dropdown-item">IPO Related Services</Link></li>
                        </ul>
                      )}
                    </li>
                    <li
                      className="sub-dropdown"
                      onMouseEnter={() => setShowNonITSubDropdown(true)}
                      onMouseLeave={() => setShowNonITSubDropdown(false)}
                    >
                      <Link className="nav-link" to="/">Consulting Services & support</Link>
                      {showNonITSubDropdown && (
                        <ul className="sub-dropdown-content">
                          <li><Link to="/consulting-services/financial-consulting" className="dropdown-item">Financial Services</Link></li>
                          <li><Link to="/consulting-services/customer-support" className="dropdown-item">Customer Support Services</Link></li>
                          <li><Link to="/consulting-services/ie" className="dropdown-item">Investment Education</Link></li>
                          <li><Link to="/consulting-services/tdts" className="dropdown-item">Technology-Driven Trading Solutions</Link></li>
                        </ul>
                      )}
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/contact">Contact Us</Link>
              </li>
              <form className="d-flex" role="search">
                <input
                  className="form-control me-3"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <a href="/" className="btn" type="submit">Search</a>
              </form>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
