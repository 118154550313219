import React from 'react';
import "../../assets/styles/about.css";
import aboutImage from "../../assets/images/about1.webp";
import aboutImage1 from "../../assets/images/about2.jpg";
import aboutImage2 from "../../assets/images/about3.avif";
import aboutImage3 from "../../assets/images/about4.avif";
import aboutImage4 from "../../assets/images/about5.jpg";
import Nidhi from "../../assets/images/CEO.jpg";
import Rishi from "../../assets/images/Account Manager.jpg";
import RishiM from "../../assets/images/Director.jpg";
import Sudesh from "../../assets/images/HR.jpg";

const teamMembers = [
  {
    name: 'Nidhi Gupta',
    title: 'Founder & CEO',
    description: 'Nidhi Gupta, the visionary founder of Carrier Launchers, has over years of experience in the financial sector. With a deep understanding of market trends and a passion for innovation, she founded the company with the goal of providing tailored financial solutions that drive success. As the CEO, Nidhi is committed to fostering a culture of excellence, integrity, and client satisfaction. Her leadership has been instrumental in shaping the company’s strategic direction, ensuring that Carrier Launchers remains at the forefront of the industry, delivering value to clients across diverse sectors.',
    image: Nidhi
  },
  {
    name: 'Rishi M',
    title: 'Director',
    description: 'As a Director at Carrier Launchers, He brings years of expertise in the financial services sector, specializing in share market, mutual funds, F&O (Futures & Options), and stock market news. With a strong background in financial consulting and customer support, Rishi plays a vital role in driving the company strategic vision. He is dedicated to providing clients with insightful market analysis, tailored financial solutions, and personalized support to navigate complex financial landscapes. His leadership ensures the seamless integration of cutting-edge financial strategies with exceptional customer service, helping clients make informed investment decisions and achieve long-term financial success.',
    image: RishiM
  },
  {
    name: 'Rishi Mahajan',
    title: 'Account Manager',
    description: 'As an Account Manager at Carrier Launchers, He brings years of experience in financial services, specializing in share markets, mutual funds, F&O (Futures & Options), and stock market news. With a focus on client relationships, Rishi is dedicated to providing personalized financial consulting, ensuring clients receive tailored strategies that meet their specific needs. He is instrumental in guiding clients through the complexities of the market, offering insightful advice and proactive support. Rishi’s commitment to exceptional service and his expertise in financial management make him a trusted partner for clients seeking to achieve their financial goals.',
    image: Rishi 
  },
  {
    name: 'Sudesh Kumar',
    title: 'HR Manager',
    description: 'As an HR Manager at Carrier Launchers, Sudesh Kumar, HR Manager at Carrier Launchers, brings years of experience in human resources, specializing in talent management, employee relations, and organizational development. With a strong passion for fostering a positive work environment, Sudesh plays a crucial role in shaping the company’s workforce strategy and ensuring alignment with organizational goals. He is dedicated to attracting, developing, and retaining top talent while fostering a culture of collaboration and growth. Sudesh’s commitment to supporting employees’ professional development and well-being makes him a key driver of Carrier Launchers success, ensuring a thriving and motivated team that delivers exceptional results.',
    image: Sudesh
  }
];


const About = () => {
  return (
    <div>
      <section className="about-section">
        {/* Background image can be added in CSS */}
      </section>
      <section className="who-we-are">
      <h3 className="section-title">Who We Are:</h3>
      <hr className="divider" />
      <div className="content">
        <div className="text">
          <p>
            Welcome to Carrier Launchers, here we are dedicated to transforming financial aspirations into reality. As a leading provider of investment and financial consulting services, we cater to individuals and businesses navigating India’s vibrant financial ecosystem. Our expertise spans share market trading, mutual funds, F&O strategies, IPO guidance, and comprehensive financial planning. With a client-first approach, we empower our clients to make informed decisions that align with their goals. By combining deep market knowledge with innovative tools, we ensure seamless access to opportunities and solutions tailored to each client's unique needs.
          </p>
          <p>
          Our team comprises seasoned professionals committed to excellence and integrity. With a focus on delivering value, we blend personalized advisory services with technology-driven insights to redefine financial services in India. At Carrier Launchers, we don’t just offer solutions; we build trust and long-term partnerships. Our mission is to simplify complex financial landscapes, enabling our clients to achieve sustainable growth. Whether you’re a first-time investor or an experienced trader, we provide the guidance, tools, and expertise necessary to thrive in today’s dynamic markets.
          </p>
          <p>
          Rooted in a vision to be India’s most trusted financial partner, we emphasize innovation, transparency, and collaboration. We believe in empowering our clients with knowledge and strategies that ensure success in an ever-evolving financial landscape. At Carrier Launchers, every service is designed to prioritize your financial well-being. Together, we can chart a path to prosperity, turning challenges into opportunities and ambitions into achievements. Discover how we can help you shape your financial future with confidence and clarity.
          </p>
        </div>
        <div className="image-container">
          <img src={aboutImage} alt="About Us" className="animated-image" />
        </div>
      </div>
    </section>
      <hr />
      <section>
        <h3>Why Choose Carrier Launchers:</h3>
        <div className="content1">
          <div className="text1">
            <hr />
            <h5>1. Client-Centric Philosophy</h5>
            <p>
              At Carrier Launchers, we place your financial goals at the forefront of our efforts. Our client-first philosophy ensures personalized solutions tailored to your unique needs and aspirations. By focusing on understanding your objectives, we create strategies that align with your short-term and long-term goals, building trust and lasting partnerships.
            </p>
            <h5>2. Expertise Across Financial Domains</h5>
            <p>
            We provide comprehensive financial services, including share market trading, mutual fund advisory, F&O strategies, IPO analysis, and wealth management. With expertise spanning multiple domains, we cater to diverse financial requirements, making us a one-stop solution for individuals and businesses seeking professional guidance.
            </p>
            <h5>3. Experienced Professionals</h5>
            <p>
            Our team consists of seasoned financial experts with years of industry experience. From market analysts to wealth advisors, we bring together professionals who are passionate about delivering exceptional results. Their insights and strategies are rooted in deep knowledge and a commitment to excellence.
            </p>
            <h5>4. Innovation and Technology</h5>
            <p>
            We leverage cutting-edge technology to deliver superior financial solutions. By integrating advanced tools, data analytics, and real-time market insights, we empower you to make informed decisions. Our focus on innovation ensures that you have access to the latest strategies and opportunities.
            </p>
            <h5>5. Integrity and Transparency</h5>
            <p>
            Trust is the foundation of our services. We operate with the highest standards of integrity and transparency, ensuring you are always informed and confident in your financial journey. Our commitment to ethical practices guarantees that your interests remain our top priority.
            </p>
            <h5>6. Tailored Investment Strategies</h5>
            <p>
            We understand that every investor is unique. Our personalized investment strategies are designed to match your risk appetite, financial goals, and market preferences. Whether you’re a first-time investor or a seasoned trader, we provide solutions that help you maximize returns while minimizing risks.
            </p>
            <h5>7. Comprehensive Market Analysis</h5>
            <p>
            Stay ahead with our in-depth market analysis and insights. From stock market trends to IPO opportunities, we provide timely updates and actionable information. Our research-backed recommendations ensure you have the knowledge to make confident investment decisions.
            </p>
            <h5>8. Dedicated Customer Support</h5>
            <p>
            At Carrier Launchers, exceptional customer service is a cornerstone of our operations. Our dedicated support team is available to address your queries, provide guidance, and ensure a seamless experience. We strive to exceed your expectations at every interaction.
            </p>
            <h5>9. Proven Track Record</h5>
            <p>
            Our history of success speaks volumes. We have helped countless clients achieve their financial goals through strategic planning, insightful advice, and unwavering support. Our proven track record of delivering results makes us a trusted partner for financial growth.
            </p>
            <h5>10. Your Partner in Success</h5>
            <p>
            Choosing Carrier Launchers means partnering with a company that values your ambitions as much as you do. We are committed to empowering you with the knowledge, tools, and expertise needed to navigate the financial landscape confidently. Together, we can achieve sustainable growth and secure your financial future.
            </p>
          </div>
          <div className="image-container1">
            <img src={aboutImage1} alt="About Us" className="animated-image1" />
            <img src={aboutImage2} alt="Our Services" className="animated-image2" />
            <img src={aboutImage3} alt="Customer Support" className="animated-image3" />
            <img src={aboutImage4} alt="Customer Support" className="animated-image4" />
          </div>
        </div>
      </section>
      <hr />
      <section className="section bg-f2f2f2 py-5">
      <div className="background-image3">
      <div className="content2">
        <h1>Industries We Serve</h1>
        <p>At Carrier Launchers, we provide tailored financial solutions to a wide range of industries, including banking, retail, healthcare, technology, manufacturing, and more. Our expert team helps businesses optimize financial performance, manage risks, and drive growth, ensuring long-term success in dynamic and competitive markets. Your success is our priority..!</p>
      </div>
    </div>
  <div className="cont">
    <div className="row">
    </div>
    <div className="row">
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Banking and Financial Services</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            We specialize in empowering the banking and financial services sector with innovative solutions that enhance operational efficiency and optimize investment strategies. Whether it's wealth management, risk mitigation, or regulatory compliance, our expertise supports financial institutions in navigating an ever-changing landscape with confidence.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Retail and E-Commerce</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            The retail and e-commerce industries thrive on agility and precision, and we deliver financial strategies tailored to these fast-paced environments. From revenue management to financial forecasting and working capital optimization, we help businesses drive growth and achieve long-term profitability.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Manufacturing and Industrial</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            Manufacturing businesses face unique financial challenges, and we are here to address them. From streamlining operational costs to managing large-scale investments, our solutions are designed to support productivity and scalability in industrial operations, ensuring sustainable success.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Technology and Startups</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            For technology companies and startups, financial clarity and growth strategies are paramount. We provide venture capital advisory, fundraising support, and financial planning services to help these innovative businesses scale, adapt, and thrive in competitive markets.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Healthcare and Pharmaceuticals</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            The healthcare and pharmaceutical industries require precision in both operations and finances. Our services include funding strategies for research and development, cost optimization, and financial planning, enabling companies to focus on innovation while staying financially sound.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Real Estate and Infrastructure</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            We offer strategic financial consulting to real estate developers and infrastructure companies. From project funding to market analysis and revenue optimization, our expertise helps clients maximize returns and successfully navigate market dynamics. 
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Energy and Utilities</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            Energy and utility companies rely on us for financial guidance in managing operational costs, securing funding for renewable energy projects, and optimizing revenue streams. We ensure these businesses are equipped to adapt to evolving regulatory and environmental demands.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Hospitality and Tourism</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            Our financial solutions for the hospitality and tourism industry are tailored to address seasonal fluctuations and market challenges. We provide revenue optimization strategies, cost management, and growth plans to help these businesses deliver exceptional experiences while maintaining profitability.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <div className="cd">
          <div className="cd-body text-center">
            <h5 className="cd-title">Agriculture and Food Processing</h5>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>
            <p className="cd-text">
            Agriculture and food processing industries require robust financial frameworks for sustainable growth. We assist with funding, investment planning, and risk management strategies that empower these sectors to innovate, expand, and thrive in a competitive global market.
            </p>
            <a href="/" className="readmore">
              read more --
            </a>
          </div>
        </div>
      </div>
      {/* You can duplicate this col-md-3 for additional cards */}
    </div>
  </div>

      </section>
      <section>
      <div className="team-container">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-card" key={index}>
            <img src={member.image} alt={`${member.name}`} />
            <h3>{member.name}</h3>
            <p className="title">{member.title}</p>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
      </section>
     <hr/>
      <section className="Aappointment">
      <div className="container">
        <div className="fact">
          <h2 className="fact-heading">Get In Touch</h2>
          <p className="fact-description">
          We’d love to hear from you! Whether you have a question, need assistance, or want to explore how we can work together, our team is here to help.
          </p>
          <a href="/" className="Abtn-appointment">Contact US</a> {/* Action Button */}
        </div>
      </div>
    </section>
    </div>
  );
}

export default About;
