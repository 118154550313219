import React, { useState, useEffect } from 'react';
import '../../../assets/styles/TDTS.css';
import BP1 from '../../../assets/images/BP1.avif'
import BP2 from '../../../assets/images/BP2.jpg';
import BP3 from '../../../assets/images/BP3.avif';
import BP4 from '../../../assets/images/BP4.avif';

const TDTS = () => {
  const slides = [
    { image: BP1, text: "Empowering Progress with Cutting-Edge Technology" },
    { image: BP2, text: "Accelerating Growth with Next-Gen Technology"},
    { image: BP3, text: "Driving Success through Advanced Solutions" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);
  const services = [
    {
      heading: "Innovative Technology:",
      items: [
        " At Carrier Launchers, we leverage the latest advancements in AI, machine learning, and big data analytics to provide innovative, market-leading solutions.",
        "Our platform incorporates cutting-edge technology to optimize trading strategies, deliver real-time insights, and ensure precision execution, empowering clients to stay ahead in an ever-evolving market landscape.",
      ],
    },
    {
      heading: "Expert Team: ",
      items: [
        "Our team is composed of financial experts, software developers, and data scientists, working collaboratively to design and implement solutions that deliver outstanding performance. ",
        "With years of industry experience, we combine deep market knowledge with advanced technical expertise to ensure that our clients benefit from the most effective and efficient trading strategies available.",
        
      ],
    },
    {
      heading: "Scalability and Flexibility:",
      items: [
        "We understand that trading needs evolve over time, which is why our systems are built for scalability and flexibility. Whether you are managing small-scale portfolios or large, high-frequency trading operations, our platform adapts seamlessly to accommodate growth, ensuring reliable, high-performance solutions no matter the market conditions.",
        
      ],
    },
    {
      heading: "Security & Compliance:",
      items: [
        "At Carrier Launhers, security and compliance are at the forefront of everything we do. Our solutions are designed to meet the highest industry standards for data protection, transaction privacy, and regulatory compliance. ",
        "We implement rigorous security protocols to ensure your trading activities remain safe, secure, and fully compliant with relevant regulations."
        
      ],
    },
  ];



  return (
    <>
       {/* Carousel Section */}
       <section className="carousl-section6">
        <div className="carousl-container6">
          <div
            className="carousel-slide7 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay6">
              <p className="carousel-txt6">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision7-section">
          <div className="containers">
            <div className="vision7-content">
              <h2 className="vision7-heading">About our Technology-Driven Trading Solutions </h2>
              <p className="vision7-description">
              Welcome to Carrier Launchers – Pioneering Technology-Driven Trading Solutions. We provide innovative, technology-driven solutions that empower financial institutions, traders, and investors to make smarter, more efficient trading decisions. Our platform integrates advanced algorithms, real-time data analytics, and machine learning to deliver high-performance systems that meet the demands of today’s markets. From high-frequency trading to comprehensive risk management, our solutions offer real-time insights, minimize latency, and enable precise execution. Designed for scalability, our systems can handle massive data volumes, ensuring fast, reliable performance. At Carrier Launchers, we focus on delivering customizable strategies tailored to individual needs, backed by optimization and backtesting tools to ensure success. With a team of experts in financial technology and data science, we’re committed to revolutionizing the trading landscape and helping our clients stay ahead in a rapidly changing environment. 
              </p>
              <a href="/" className="readmore7">
                    read more --
                  </a>
            </div>
            <div className="vision7-image">
              <img src={BP4} alt="Vission7" />
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="cust-section cust-bg-f2f2f2 cust-py-5">
        <div className="cust-background-image3">
          <div className="cust-content2">
            <h1>End-to-End Technology-Driven Trading Solutions</h1>
            <p>
            We aim to redefine the future of trading by integrating technology with finance, enabling our clients to make data-driven decisions and execute strategies with precision, speed, and confidence.
            </p>
          </div>
        </div>
        <div className="cust-cont">
          <div className="cust-row">
          </div>
          <div className="cust-row">
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Algorithmic Trading Solutions</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our algorithmic trading solutions are designed to enhance trading efficiency, executing high-frequency trades with minimal latency. By using advanced mathematical models and automated decision-making, we optimize market entries and exits, ensuring that clients capitalize on profitable opportunities while reducing human error and operational risk.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Real-Time Data Analytics</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our real-time data analytics tools provide traders with actionable insights that drive smarter decisions. By analyzing vast amounts of market data, we deliver predictive analytics and market trend analysis, empowering clients to identify profitable opportunities, anticipate market shifts, and stay ahead of the competition in fast-moving environments.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Machine Learning Models</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  We integrate machine learning models into our trading platforms to enable adaptive decision-making based on historical and real-time data. These intelligent models evolve over time, learning from market patterns and behavior, helping clients refine strategies, minimize risks, and improve overall trading performance by predicting future trends more accurately.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Risk Management Solutions</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our advanced risk management tools ensure that clients can safeguard their investments in volatile markets. Real-time risk monitoring, customizable alerts, and automated controls allow users to manage exposure and take corrective actions when needed, minimizing potential losses and ensuring consistent performance in all market conditions.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Trading Automation & Execution</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our trading automation solutions streamline the execution process, allowing trades to be executed at high speed with precision. By reducing manual intervention, we ensure that clients can execute complex strategies efficiently, enhance order fulfillment, and maintain optimal execution, especially in high-volume, fast-moving markets.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Customizable Trading Strategies</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  We offer customizable trading strategies tailored to the unique needs of each client. Whether you prefer quantitative models, technical analysis, or discretionary trading, our platform supports backtesting, optimization, and strategy adaptation, allowing you to design, refine, and implement strategies with flexibility and precision for superior market outcomes.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Advanced Market Analytics & Reporting</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our market analytics tools provide in-depth reporting, tracking key performance indicators and offering insights into market behavior. Customizable dashboards and reports give clients an easy way to monitor performance, evaluate trading strategies, and track risk exposure, ensuring transparency and better decision-making capabilities across all trading activities.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Scalable Infrastructure</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our solutions are built on scalable infrastructure, allowing clients to seamlessly handle increased data loads and trading volumes. With robust cloud computing capabilities and high-performance technology, we ensure that as your trading needs grow, our platform adapts to provide continuous, reliable support with low latency and enhanced capacity.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Compliance & Security</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  We prioritize compliance and security by integrating robust measures into our trading solutions. Our systems adhere to industry regulations and standards, ensuring that data integrity, transaction privacy, and security are maintained. With built-in compliance tools and regular updates, we ensure our clients meet regulatory requirements effortlessly.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            <ul className="content-list">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    <hr/>
    <section className="appointmentb">
      <div className="containerb">
        <div className="factb">
          <h2 className="fact-headingb">How Can We Assist You?</h2>
          <p className="fact-descriptionb">
          We value your feedback and are here to assist with any inquiries or support you may need. Please feel free to reach out to us
          </p>
          <a href="/" className="btn-appointmentb">Book Now</a> {/* Action Button */}
        </div>
      </div>
    </section>

    </>
  )
}


export default TDTS;
