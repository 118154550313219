import React, { useState, useEffect } from 'react';
import '../../../assets/styles/FinancialConsulting.css';
import FC1 from '../../../assets/images/FC1.webp'
import FC2 from '../../../assets/images/FC2.jpeg';
import FC3 from '../../../assets/images/FC3.jpg';
import FC4 from '../../../assets/images/FC4.jpg';

const FinancialConsulting = () => {
  const slides = [
    { image: FC1, text: "Empowering Your Business with Strategic Financial Insights to Drive Growth, Optimize Performance, and Navigate Complex Financial Landscapes with Confidence and Clarity for Long-Term Success." },
    { image: FC2, text: "Guiding You Towards Financial Excellence with Expert Consulting, Strategic Planning, and Data-Driven Solutions that Help Navigate Complexity, Enhance Profitability, and Secure Long-Term Business Success." },
    { image: FC3, text: "Building Stronger Financial Foundations with Expert Consulting, Innovative Solutions, and Comprehensive Strategies that Deliver Clarity, Improve Efficiency, and Empower Your Business to Achieve Long-Term Success." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section2">
        <div className="carousl-container2">
          <div
            className="carousel-slide3 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay2">
              <p className="carousel-txt2">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision3-section">
          <div className="containerp">
            <div className="vision3-content">
              <h2 className="vision3-heading">Our Mission</h2>
              <p className="vision3-description">
                At Carrier Launchers, our mission is to provide businesses with expert financial consulting services
                that drive operational efficiency, strategic growth, and financial resilience. We are dedicated to
                delivering customized solutions that address each client's unique needs, leveraging advanced technology
                and industry insights to optimize financial processes, ensure compliance, and enable data-driven
                decision-making. Through our commitment to excellence, integrity, and innovation, we aim to help our
                clients navigate the complexities of the financial world and achieve sustainable success in an ever-evolving
                business environment.
              </p>
              <a href="/" className="readmore3">
                read more --
              </a>
            </div>
            <div className="vision3-image">
              <img src={FC4} alt="Vission3" />
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="custom-section custom-bg-f2f2f2 custom-py-5">
        <div className="custom-bg-image3">
          <div className="custom-content2">
            <h1>Financial Consulting Services</h1>
            <p>
              Carrier Launchers providing financial consulting services typically blends financial expertise with technology
              to offer comprehensive solutions for businesses and individuals. Below are main financial
              consulting services that we offer:
            </p>
          </div>
        </div>
        <div className="custom-cont">
          <div className="custom-row">
          </div>
          <div className="custom-row">
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Financial Planning and Analysis (FP&A)</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    We provide <strong>end-to-end budgeting and forecasting services</strong>, including developing <strong>annual budgets</strong>, <strong>rolling forecasts</strong>, and <strong>variance analysis</strong> using tailored <strong>tools and templates</strong> for effective <strong>financial management</strong>. Our expertise extends to <strong>financial modeling</strong>, offering <strong>dynamic models</strong> for <strong>valuation</strong>, <strong>cash flow forecasting</strong>, and <strong>strategic decision-making</strong>, with <strong>sensitivity analysis</strong> for diverse scenarios. Through <strong>performance analysis</strong>, we monitor key financial metrics like <strong>ROI</strong> and <strong>EBITDA</strong>, <strong>benchmark industry standards</strong>, and provide <strong>actionable insights</strong>. Additionally, we excel in <strong>scenario planning</strong> by preparing <strong>financial scenarios</strong> (best-case, worst-case), analyzing <strong>macroeconomic impacts</strong>, and offering <strong>recommendations</strong> to address uncertainties, ensuring robust <strong>financial planning</strong> and <strong>strategic alignment</strong> for businesses.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title"> Investment Advisory and Portfolio Management</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    We offer comprehensive <strong>Investment Advisory and Portfolio Management</strong> services, including <strong>Asset Allocation and Risk Management</strong> to strategically balance investments across <strong>equities</strong>, <strong>bonds</strong>, and <strong>alternatives</strong>, with advanced <strong>risk assessment tools</strong> to manage portfolio volatility. Our <strong>Investment Strategies</strong> are tailored to align with your financial goals and <strong>risk appetite</strong>, featuring active monitoring and portfolio adjustments based on <strong>market conditions</strong>. We also provide expert <strong>Retirement Planning</strong>, focusing on <strong>tax-advantaged retirement saving strategies</strong> and optimizing investment returns for long-term <strong>financial security</strong>. Additionally, our <strong>Wealth Management</strong> services cater to both <strong>individuals</strong> and <strong>corporates</strong>, offering customized solutions that integrate <strong>tax</strong>, <strong>investment</strong>, and <strong>legacy planning</strong> for high-net-worth clients.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Tax Planning and Compliance</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Tax Planning and Compliance</strong> services include <strong>Tax Strategy and Optimization</strong>, where we design strategies for <strong>income deferral</strong>, <strong>deductions</strong>, and <strong>credits</strong>, and structure business operations for maximum <strong>tax efficiency</strong>. We provide expert <strong>International Tax Planning</strong>, ensuring compliance with <strong>multi-jurisdictional tax laws</strong> and offering advice on <strong>treaties</strong>, <strong>double taxation</strong>, and <strong>cross-border transactions</strong>. Our <strong>Estate and Inheritance Planning</strong> services focus on minimizing <strong>inheritance tax</strong> and facilitating smooth wealth transfer, while structuring <strong>trusts</strong> and <strong>wills</strong> to meet client objectives. Additionally, we offer comprehensive <strong>Tax Compliance and Filing Assistance</strong>, providing end-to-end support for <strong>local</strong>, <strong>federal</strong>, and <strong>international tax filings</strong>, and leveraging <strong>technology</strong> to automate tax compliance processes.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Mergers and Acquisitions (M&A) Advisory</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Mergers and Acquisitions (M&A) Advisory</strong> services provide expert <strong>M&A Strategy</strong> guidance, focusing on target identification and <strong>deal structuring</strong>, ensuring alignment of M&A goals with long-term <strong>corporate strategies</strong>. We offer thorough <strong>Due Diligence</strong>, assessing financial records, <strong>operational risks</strong>, and <strong>market opportunities</strong>, while identifying potential <strong>red flags</strong> or synergies. Our <strong>Valuation Services</strong> deliver precise valuations of companies or assets using <strong>market-based</strong> and <strong>discounted cash flow (DCF)</strong> analysis techniques for buy/sell negotiations. Additionally, we provide comprehensive <strong>Integration Planning</strong> and <strong>Post-Merger Support</strong>, creating a detailed roadmap for integrating <strong>operational</strong>, <strong>financial</strong>, and <strong>cultural aspects</strong> post-merger.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Debt and Financing Consulting</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Debt and Financing Consulting</strong> services include expert <strong>Debt Restructuring and Refinancing</strong>, where we assist with renegotiating loan terms to ease financial burden and provide <strong>debt consolidation</strong> or optimization strategies. We offer <strong>Capital Structure Advisory</strong>, providing recommendations on balancing <strong>debt vs. equity</strong> for optimal growth and evaluating financing options for <strong>strategic objectives</strong>. Our <strong>Debt Sourcing and Negotiations</strong> services support clients in accessing funding from <strong>banks</strong>, private lenders, or markets, and negotiating favorable terms to secure optimal <strong>interest rates</strong> or conditions. Additionally, we provide comprehensive <strong>Loan and Credit Analysis</strong>, assessing loan proposals and <strong>creditworthiness</strong>, while offering strategic advice for managing existing <strong>credit obligations</strong>.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Risk Management Consulting</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Risk Management Consulting</strong> services include <strong>Risk Identification and Assessment</strong>, where we map <strong>financial</strong>, <strong>operational</strong>, and <strong>market risk exposures</strong> using frameworks like <strong>SWOT</strong> or <strong>PESTEL analysis</strong>. We specialize in <strong>Enterprise Risk Management (ERM)</strong>, implementing comprehensive risk management systems and providing training on <strong>risk awareness</strong> and response planning. Our <strong>Credit Risk</strong> and <strong>Operational Risk Analysis</strong> services assess <strong>debtor quality</strong> and <strong>operational vulnerabilities</strong>, utilizing tools for <strong>risk quantification</strong> and <strong>prioritization</strong>. Additionally, we offer <strong>Risk Mitigation</strong> and <strong>Strategy Development</strong>, creating <strong>contingency plans</strong> to address identified risks and employing <strong>hedging strategies</strong>, <strong>insurance</strong>, or other financial instruments to minimize exposure.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Retirement and Pension Planning</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Retirement and Pension Planning</strong> services include <strong>Pension Fund Management</strong>, where we develop <strong>portfolio strategies</strong> for steady growth and low volatility, ensuring compliance with <strong>pension regulations</strong>. We specialize in <strong>Retirement Plan Design</strong> (e.g., <strong>401k</strong>, <strong>IRAs</strong>), structuring <strong>defined contribution</strong> and <strong>benefit plans</strong>, and providing tools to project <strong>retirement income sufficiency</strong>. Our <strong>Defined Benefit Plan Consulting</strong> includes <strong>actuarial analysis</strong> and funding strategies, as well as guidance on transitioning to <strong>hybrid plans</strong> if needed. Additionally, we offer <strong>Social Security and Healthcare Planning</strong>, focusing on the <strong>optimization of social security benefits</strong> and <strong>retirement healthcare</strong>, along with <strong>forecasting long-term care costs</strong>.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Estate and Succession Planning</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Estate and Succession Planning</strong> services include the <strong>legal and tax-compliant creation of wills</strong> and <strong>trust structures</strong>, designed to minimize <strong>probate costs</strong>. We provide <strong>Succession Planning for Business Owners</strong>, ensuring continuity and avoiding disputes in <strong>family-owned businesses</strong>, with expertise in structuring <strong>buy-sell agreements</strong> and <strong>leadership transitions</strong>. Our <strong>Asset Protection Strategies</strong> focus on shielding assets from <strong>litigation</strong> or <strong>creditors</strong> through trusts or entities, along with effective <strong>estate tax reduction</strong> strategies. Additionally, we offer <strong>Charitable Giving Strategies</strong>, helping to structure <strong>donations</strong> for maximum <strong>tax benefits</strong> and establish <strong>foundations</strong> or <strong>donor-advised funds</strong>.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Corporate Governance and Compliance Consulting</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Corporate Governance and Compliance Consulting</strong> services include the development of <strong>governance frameworks</strong> and <strong>strategies</strong> for board oversight and accountability, along with establishing <strong>whistleblower</strong> and <strong>ethics programs</strong>. We ensure <strong>regulatory compliance</strong> with standards like <strong>SOX</strong> and <strong>IFRS</strong>, focusing on adherence to financial reporting requirements and conducting <strong>auditing and remediation</strong> of compliance gaps. Our expertise in <strong>internal controls</strong> and <strong>audits</strong> helps strengthen financial reporting and operational processes, while we provide <strong>financial reporting</strong> services, ensuring the preparation of statements in line with <strong>GAAP</strong>/<strong>IFRS</strong> standards, leveraging <strong>automated reporting software</strong>.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Financial Restructuring and Turnaround Consulting</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Financial Restructuring and Turnaround Consulting</strong> services provide expert <strong>bankruptcy and insolvency advisory</strong>, helping distressed businesses navigate legal and financial proceedings while managing <strong>stakeholder communication</strong> during insolvency. We offer <strong>corporate recovery strategies</strong> that identify both short-term liquidity solutions and long-term <strong>restructuring plans</strong>, along with <strong>stakeholder management</strong> to maintain trust. Our focus on <strong>cost reduction and profit improvement</strong> includes implementing <strong>operational efficiency programs</strong> and <strong>supply chain optimization</strong>, alongside <strong>lean management strategies</strong> to minimize waste. Additionally, we conduct <strong>operational efficiency reviews</strong>, providing in-depth analysis of workflows, costs, and processes, and implement <strong>technology solutions</strong> to streamline operations.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Financial Data Governance and Compliance</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Financial Data Governance and Compliance</strong> services focus on <strong>implementing financial data management strategies</strong>, ensuring data quality, security, and accessibility through frameworks that establish centralized data repositories for seamless compliance. We provide <strong>integration of financial systems with compliance tools</strong>, automating audit trails and regulatory reporting while ensuring system interoperability. Additionally, we offer <strong>cloud-based compliance and reporting solutions</strong>, adopting scalable cloud tools for financial processes and enabling <strong>real-time tracking</strong> and reporting for global operations.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Hybrid Financial Consulting Services</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                    Our <strong>Hybrid Financial Consulting Services</strong> include the deployment of <strong>AI-driven financial planning tools</strong> through <strong>digital financial planning platforms</strong>, offering <strong>user-friendly interfaces</strong> for personalized insights. We support <strong>financial transformation and digitalization</strong> by adopting <strong>ERP</strong> and <strong>cloud financial systems</strong>, enhancing <strong>data analytics</strong> for improved strategic decision-making. Additionally, we provide <strong>financial data visualization</strong> with custom dashboards for <strong>management</strong> and <strong>operational reporting</strong>, utilizing tools like <strong>Power BI</strong>, <strong>Tableau</strong>, or other custom visualization software.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            {/* Repeat this block for other sections */}
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="choose-container">
          <h3 className="choose-header">Why Choose Us?</h3>
          <ol className="choose-list">
            <li>
              <h4 className="choose-heading">Technology-First Approach</h4>
              <p>
                We leverage AI, machine learning, and big data analytics to
                provide intelligent financial insights. Our tools not only improve
                accuracy in forecasting but also uncover growth opportunities and
                identify risks early.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Tailored Financial Solutions</h4>
              <p>
                Our solutions are customized to meet your specific goals,
                providing personalized dashboards, APIs, and integrated platforms
                that streamline financial processes and deliver actionable
                insights.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Seamless System Integration</h4>
              <p>
                We ensure smooth integration of ERP, CRM, and cloud-based
                financial systems, giving you a unified view of your business's
                financial health and operational efficiency.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Real-Time Monitoring and Automation</h4>
              <p>
                Our proprietary platforms provide real-time financial tracking and
                reporting, while automated workflows eliminate manual errors and
                inefficiencies, saving time and reducing costs.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Scalability and Flexibility</h4>
              <p>
                Whether you are a small startup or a multinational corporation,
                our cloud-based financial systems are scalable to grow with your
                business, ensuring flexibility at every stage.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Comprehensive Risk Management</h4>
              <p>
                Our advanced risk assessment frameworks help businesses identify
                and mitigate financial, operational, and market risks, protecting
                your assets and ensuring long-term stability.
              </p>
            </li>
            <li>
              <h4 className="choose-heading">Compliance and Data Security</h4>
              <p>
                With robust security protocols and compliance expertise, we ensure
                adherence to global financial regulations like SOX, GDPR, and
                IFRS, safeguarding your data and reputation.
              </p>
            </li>
          </ol>
        </div>
      </section>
      <hr />
      <section>
        <div className="apart-container">
          <h3 className="apart-header">What Sets Us Apart?</h3>
          <ul className="apart-list">
            <li>
              <h4 className="apart-heading">Expert Team</h4>
              <p>
                A dedicated group of financial consultants with decades of combined experience.
              </p>
            </li>
            <li>
              <h4 className="apart-heading">Cutting-Edge Technology</h4>
              <p>
                Proprietary tools and frameworks that drive smarter decision-making.
              </p>
            </li>
            <li>
              <h4 className="apart-heading">Client-Centric Approach</h4>
              <p>
                Tailored strategies designed to deliver measurable results and exceed expectations.
              </p>
            </li>
            <li>
              <h4 className="apart-heading">Proven Track Record</h4>
              <p>
                Trusted by businesses across industries for delivering scalable, impactful financial solutions.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <hr/>
      <section className="appointmentf">
      <div className="containerf">
        <div className="factf">
          <h2 className="fact-headingf">Book an Appointment</h2>
          <p className="fact-descriptionf">
            Schedule an appointment with our team to discuss your business needs, explore our services, 
            and discover solutions that can take your business to the next level.
          </p>
          <a href="/" className="btn-appointmentf">Book Now</a> {/* Action Button */}
        </div>
      </div>
    </section>
    </>
  )
}

export default FinancialConsulting;
