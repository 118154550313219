import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';

import ShareMarketServices from './components/pages/Financial Marketing/ShareMarketServices';
import MutualFunds from './components/pages/Financial Marketing/MutualFunds';
import FO from './components/pages/Financial Marketing/FO';
import IPO from './components/pages/Financial Marketing/IPO';

import FinancialConsulting from './components/pages/Consulting Services/FinancialConsulting';
import IE from './components/pages/Consulting Services/IE';
import CustomerSupport from './components/pages/Consulting Services/CustomerSupport';
import TDTS from './components/pages/Consulting Services/TDTS';

const App = () => {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
      }}
    >
      <div>
        <Navbar />
        <Routes>
          {/* Core Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          {/* IT Services */}
          <Route path="/financial-marketing/share-market-services" element={<ShareMarketServices />} />
          <Route path="/financial-marketing/mutual-funds" element={<MutualFunds />} />
          <Route path="/financial-marketing/fo" element={<FO />} />
          <Route path="/financial-marketing/ipo" element={<IPO />} />

          {/* Non-IT Services */}
          <Route path="/consulting-services/financial-consulting" element={<FinancialConsulting />} />
          <Route path="/consulting-services/ie" element={<IE />} />
          <Route path="/consulting-services/customer-support" element={<CustomerSupport />} />
          <Route path="/consulting-services/tdts" element={<TDTS />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
