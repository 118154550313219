import React, { useState, useEffect } from 'react';
import '../../../assets/styles/CustomerSupport.css';
import CSC1 from '../../../assets/images/CSC1.jpg';
import CSC2 from '../../../assets/images/CSC2.avif';
import CSC3 from '../../../assets/images/CSC3.avif';
import CSC4 from '../../../assets/images/CSC4.avif';

const CustomerSupport = () => {
  const slides = [
    { image: CSC1, text: "Solving Problems, One Call at a Time" },
    { image: CSC2, text: "Your Problem, Our Priority." },
    { image: CSC3, text: "We're Here to Help, Anytime, Anywhere." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const services = [
    {
      heading: "Proven Expertise and Experience:",
      items: [
        "With years of experience, our team has honed a deep understanding of diverse industries and customer needs. We leverage our expertise to provide tailored software solutions that deliver measurable results.Our proven track record ensures that your business will benefit from industry-leading practices and solutions.",
        
      ],
    },
    {
      heading: "Customer-Centric Approach:",
      items: [
        "We prioritize your needs, listening closely to understand your unique challenges and goals. Our services are designed to provide maximum value by aligning with your business objectives. With dedicated customer success managers, we ensure continuous support to help you achieve long-term success.",
        
      ],
    },
    {
      heading: "Innovative Solutions:",
      items: [
        "Constantly evolving, we integrate the latest technologies to stay ahead of industry trends.Our innovative approach ensures that our solutions are future-proof and adaptable to market changes.We deliver cutting-edge software that drives business growth while maintaining flexibility for emerging needs.",
       
      ],
    },
    {
      heading: "Tailored Solutions for Your Business:",
      items: [
        "We believe in creating bespoke solutions tailored to meet the specific requirements of your business. Our team works closely with you to align our software with your company’s vision and goals.With customized features, we ensure that the solution fits seamlessly into your workflows and drives real results.",
        
      ],
    },
    {
      heading: "Reliable and Scalable Solutions:",
      items: [
        "Our software is designed to be scalable, so it grows with your business as you expand.Whether you're a startup or an enterprise, we provide reliable solutions that adapt to your changing needs.You can rely on us to equip your business with the tools necessary to scale effectively and efficiently.",
        
      ],
    },
    {
      heading: "Comprehensive Support and Training:",
      items: [
        "We provide ongoing training to ensure your team maximizes the value of our software.Our 24/7 support team is always available to address any concerns, ensuring smooth operations.From initial onboarding to advanced feature usage, we guide you every step of the way.",
        
      ],
    },
    {
      heading: "Competitive Pricing and Flexibility:",
      items: [
        "Our pricing is competitive, designed to fit a wide range of budgets, without compromising quality.We offer flexible terms that give you the freedom to adjust services as needed.Whether you’re looking for a short-term solution or a long-term partnership, we adapt to your needs.",
        
      ],
    },
    {
      heading: "Customer Satisfaction Guarantee:",
      items: [
        "Your satisfaction is our top priority, and we are committed to delivering high-quality, value-driven solutions.We work tirelessly to ensure our software meets your expectations and business needs.If you're not satisfied, we’ll go the extra mile to make things right and improve the service.",
        
      ],
    },
    {
      heading: "Industry Recognition and Trust:",
      items: [
        "Trusted by leading businesses across various industries, we have built a strong reputation for excellence.Our clients rely on us for our professionalism, expertise, and commitment to high-quality solutions.We have been consistently recognized for our ability to deliver solutions that create lasting impact.",
        
      ],
    },
    {
      heading: "Proactive and Results-Oriented:",
      items: [
        "We anticipate your needs and act before challenges arise, ensuring smooth and uninterrupted service.Our proactive approach allows us to identify opportunities and solve problems before they become obstacles.Our results-oriented mindset ensures that you achieve the outcomes that matter most, faster and more efficiently.",
        
      ],
    },
  ];

  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section5">
        <div className="carousl-container5">
          <div
            className="carousel-slide6 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay5">
              <p className="carousel-txt5">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision6-section">
          <div className="containerr">
            <div className="vision6-content">
              <h2 className="vision6-heading">Our Mission</h2>
              <p className="vision6-description">
                Our mission is to deliver exceptional customer support that empowers our clients to fully leverage our software solutions. We are committed to providing timely, effective, and personalized assistance to resolve any challenges, ensuring that our clients experience seamless interactions and maximum value from our products. With a customer-first approach, we aim to foster long-term relationships built on trust, responsiveness, and a deep understanding of our clients' unique needs. Our support team is dedicated to offering proactive guidance, clear communication, and continuous improvement to enhance user satisfaction and contribute to the success of our clients' businesses.
              </p>
              <a href="/" className="readmore6">
                read more --
              </a>
            </div>
            <div className="vision6-image">
              <img src={CSC4} alt="Vission6" />
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="custom-section custom-bg-f2f2f2 custom-py-5">
        <div className="custom-background-image3">
          <div className="custom-content2">
            <h1>Customer Support Services</h1>
            <p>
            In Carrier launchers, we often focus on providing value to clients beyond technical solutions. These services aim to enhance the overall customer experience and ensure business success. Here are some common customer services we offer:
            </p>
          </div>
        </div>
        <div className="custom-cont">
          <div className="custom-row">
          </div>
          <div className="custom-row">
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">24/7 Customer Support</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>24/7 Customer Support</strong> service ensures that customers receive assistance at any time, day or night. Whether it's for <strong>technical issues</strong>, <strong>account management</strong>, or product inquiries, our dedicated team is always available to resolve concerns promptly. This around-the-clock support helps <strong>improve customer satisfaction</strong>, as customers can get timely help whenever they need it. By offering continuous support, we also <strong>reduce downtime</strong>, allowing customers to maintain productivity and minimize disruptions. With 24/7 availability, we are committed to providing a seamless and reliable experience, ensuring that help is always just a call or click away.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Inbound Call Handling</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Inbound Call Handling</strong> service is designed to manage customer calls efficiently, whether for inquiries, <strong>technical support</strong>, or <strong>order tracking</strong>. Our support teams are specifically trained to handle a wide variety of customer queries in a <strong>timely</strong> and <strong>professional</strong> manner. By providing clear and concise responses, we ensure that each customer receives the information or assistance they need, contributing to a <strong>positive customer experience</strong>. This service is crucial for maintaining strong customer relationships, as it fosters trust and satisfaction by delivering prompt and reliable support every time a customer reaches out.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Outbound Call Services</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Outbound Call Services</strong> involve making proactive calls to customers for <strong>follow-ups</strong>, <strong>feedback surveys</strong>, <strong>product updates</strong>, or <strong>troubleshooting</strong>. These calls enable businesses to stay connected with customers, ensuring they are informed and satisfied. By gathering <strong>valuable insights</strong> through customer interactions, we help identify potential issues early and resolve them before they escalate. This service not only enhances customer engagement but also strengthens relationships by showing customers that their concerns are actively addressed, ultimately leading to improved customer loyalty and retention.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Live Chat Support</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Live Chat Support</strong> service enables customers to receive <strong>real-time assistance</strong> directly on the company's website or within the software application. This fast and efficient communication channel allows customers to quickly resolve issues without the need to wait on hold for a call. Live chat support offers a more immediate and convenient way to address <strong>technical problems</strong>, <strong>product inquiries</strong>, or account management questions. It enhances the overall customer experience by providing timely resolutions, ensuring customer satisfaction, and improving the effectiveness of customer support teams.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Email Support</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Email Support</strong> service allows customers to submit <strong>issues or inquiries</strong> in writing, which can be addressed at a later time. This service is ideal for <strong>non-urgent inquiries</strong> or for customers who prefer <strong>written communication</strong> over other support channels. A dedicated response team monitors the email inbox to ensure all queries are answered <strong>promptly</strong> and thoroughly. Email support offers a convenient and efficient way for customers to reach out, providing them with detailed written responses and documentation for reference. This enhances customer satisfaction while managing workloads effectively.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Self-Service Support (FAQs and Knowledge Base)</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Self-Service Support</strong>, including a comprehensive <strong>FAQ</strong> section and <strong>knowledge base</strong>, allows customers to find answers to common questions and resolve minor issues independently. By providing easy access to detailed guides, troubleshooting steps, and solutions, customers can quickly address concerns without the need to contact support. This <strong>self-service</strong> option enhances overall efficiency, reduces <strong>call volume</strong>, and empowers customers to resolve issues on their own. It not only saves time for both customers and support teams but also helps to improve <strong>customer satisfaction</strong> by offering instant access to essential information.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Technical Support & Troubleshooting</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Technical Support & Troubleshooting</strong> service offers specialized assistance for any technical issues customers may encounter while using the software. Whether it’s <strong>error messages</strong>, <strong>system malfunctions</strong>, or configuration problems, our support team is equipped to diagnose and resolve issues quickly. This service ensures that users can continue using the software seamlessly, with minimal disruptions. By providing expert troubleshooting, we help customers <strong>resolve technical challenges</strong> promptly, keeping the software <strong>running smoothly</strong> and ensuring a positive user experience.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Customer Feedback & Satisfaction Surveys</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our <strong>Customer Feedback & Satisfaction Surveys</strong> service involves collecting valuable insights from customers through <strong>calls</strong> or <strong>automated surveys</strong>. This service is essential for identifying areas for improvement, allowing the company to understand <strong>customer pain points</strong>, gather feedback on the <strong>user experience</strong>, and enhance both the software and <strong>support services</strong> based on direct input. By actively engaging with customers to evaluate their satisfaction, we can continuously refine our offerings, ensuring that customers receive the best possible experience and that their needs are consistently met.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            {/* Repeat this block for other sections */}
          </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            <ul className="content-list">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    <hr/>
    <section className="appointmentcs">
      <div className="containercs">
        <div className="factcs">
          <h2 className="fact-headingcs">Help & Support
          </h2>
          <p className="fact-descriptioncs">
          We value your feedback and are here to assist with any inquiries or support you may need. Please feel free to reach out to us
          </p>
          <a href="/" className="btn-appointmentcs">Contact Us</a> {/* Action Button */}
        </div>
      </div>
    </section>
    </>
  );
};

export default CustomerSupport;
