import React, { useState, useEffect } from "react";
import '../../../assets/styles/F&O.css';
import SS1 from '../../../assets/images/SS1.webp'
import SS2 from '../../../assets/images/SS2.jpg';
import SS3 from '../../../assets/images/SS3.webp';
import SS4 from '../../../assets/images/SS4.jpg';
import SS5 from '../../../assets/images/SS5.jpg';
import SS6 from '../../../assets/images/SS6.webp';
import SS7 from '../../../assets/images/SS7.jpg';
import SS8 from '../../../assets/images/SS8.jpg';
import SS9 from '../../../assets/images/SS9.jpg';
import SS10 from '../../../assets/images/SS10.jpg';

const FO = () => {

  const slides = [
    { image: SS1, text: "Redefining Futures and Options, Redefining Success." },
    { image: SS2, text: "Transforming Opportunities into Profits." },
    { image: SS3, text: "Your Partner in Smarter Trading Decisions" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section">
        <div className="carousl-container">
          <div
            className="carousel-slide1 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay">
              <p className="carousel-txt">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision1-section">
          <div className="containerm">
            <div className="vision1-content">
              <h2 className="vision1-heading">Future and Options Services</h2>
              <p className="vision1-description">Unlock New Financial Opportunities with Cutting-Edge Technology<br/>
              Future and Options Services Unlock New Financial Opportunities with Cutting-Edge Technology
              In today’s dynamic financial landscape, futures and options trading is vital for managing risks, maximizing profits, and diversifying portfolios. At CARRIER LAUNCHERS, we offer state-of-the-art Future and Options Services designed to meet the complex needs of traders, investors, and financial institutions.
              Our advanced trading platforms deliver real-time market data, algorithmic execution, and intuitive analytics to empower informed decision-making. We also provide robust risk management tools, including portfolio stress testing and hedging strategies, to safeguard your investments.

With expertise-driven insights and seamless integration across global exchanges, our solutions are tailored to optimize your trading experience. Whether you’re a seasoned trader or new to derivatives, CARRIER LAUNCHERS equips you with innovative tools and 24/7 support to succeed in this fast-paced domain.
              </p>
              <a href="/" className="readmore1">
                read more --
              </a>
            </div>
            <div className="vision1-image">
              <img src={SS4} alt="Vission1" />
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="about-it-staffing">
        <h3 className="section-title">About Our Futures and Options (FO) Services:</h3>
        <hr className="divider1" />
        <div className="contxt">
          <div className="textt">
            <p>
            At CARRIER LAUNCHERS, we specialize in providing cutting-edge Futures and Options (FO) Services tailored to meet the evolving needs of traders, investors, and financial institutions. These services empower our clients to manage risks effectively, optimize returns, and diversify their portfolios with confidence.
            </p>
            <p>
            Futures and options are essential tools for navigating the complexities of financial markets. They offer unique opportunities to hedge against price volatility, speculate on market movements, and achieve strategic financial goals. However, trading these instruments successfully requires a combination of advanced technology, deep market insights, and reliable support.
            </p>
            <p>
            Our comprehensive FO services include advanced trading platforms with real-time market data, algorithmic trading tools, and customizable dashboards for a seamless trading experience. Our robust risk management solutions help you assess portfolio risks, simulate market scenarios, and implement effective hedging strategies.
            </p>
            <p>
            At CARRIER LAUNCHERS, we also provide AI-driven analytics and predictive modeling to help you make informed decisions. Our services integrate seamlessly with leading global exchanges, ensuring compliance and security. To support our clients further, we offer educational resources, training sessions, and round-the-clock expert assistance.
            </p>
            <p>
            We take pride in our client-centric approach, combining financial expertise with innovative technology to deliver personalized solutions. Whether you’re a seasoned trader or new to derivatives, CARRIER LAUNCHERS is committed to helping you achieve success in the fast-paced world of futures and options trading.
            </p>
          </div>
          <div className="pic-container">
            <img src={SS5} alt="IT Staffing" className="animated-pic" />
          </div>
        </div>
      </section>
      <hr />
      <section className="sect bg-f2f2f2 py-5">
        <div className="containerz">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect-title">Our
                <span className="text-danger"> Comprehensive  </span> Services
              </h2>
              <div
                className="underline mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              <p className="sect-subtitle">
                The best services provided by us
              </p>
            </div>
          </div>

          {/* Capabilities Cards */}
          <div className="row">
            {/* Card 1 */}
            <div className="col-md-4 mt-3">
              <div className="card h-100">
                <img src={SS6} className="card-img-top" alt="Technology-Specific" />
                <div className="card-body text-center">
                  <h5 className="card-title">Advanced Trading Platforms</h5>
                  <div
                    className="underline mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our sophisticated trading platforms are designed to provide a seamless and efficient trading experience. Equipped with real-time market data, intuitive interfaces, and advanced automation features, our platforms empower you to execute trades with confidence and precision. From building complex options strategies to executing multi-leg orders, our platforms cater to the needs of traders at all levels. Additionally, with compatibility across desktop and mobile devices, you can trade anytime and anywhere without compromising functionality.
                  </p>
                  <a href="/" className="readmore">
                    Read More →
                  </a>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-md-4 mt-3">
              <div className="card h-100">
                <img src={SS7} className="card-img-top" alt="Temporary and Permanent" />
                <div className="card-body text-center">
                  <h5 className="card-title">Risk Management Solutions</h5>
                  <div
                    className="underline mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Effective risk management is the backbone of successful trading. Our cutting-edge risk management solutions are built to help you safeguard your investments and optimize returns. With tools like portfolio stress testing, delta-neutral hedging strategies, and real-time margin monitoring, you can anticipate risks and act proactively. These capabilities enable you to maintain a balanced portfolio and ensure optimal capital allocation even in volatile markets.
                  </p>
                  <a href="/" className="readmore">
                    Read More →
                  </a>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-md-4 mt-3">
              <div className="card h-100">
                <img src={SS8} className="card-img-top" alt="IT Consulting & Managed Services" />
                <div className="card-body text-center">
                  <h5 className="card-title">Market Intelligence and Analytics
                  </h5>
                  <div
                    className="underline mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Make smarter, data-driven decisions with our advanced market intelligence and analytics tools. Our system processes vast amounts of market data to provide actionable insights and forecasts. With customizable dashboards, volatility modeling, and real-time Option Greeks calculations, you gain a deeper understanding of market dynamics, enabling precise strategy adjustments. These analytics ensure you stay ahead in the fast-paced world of derivatives trading.
                  </p>
                  <a href="/" className="readmore">
                    Read More →
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card h-100">
                <img src={SS9} className="card-img-top" alt="IT Consulting & Managed Services" />
                <div className="card-body text-center">
                  <h5 className="card-title"> Seamless Integration with Major Exchanges
                  </h5>
                  <div
                    className="underline mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Expand your trading reach with seamless connectivity to leading global exchanges. Our platform ensures smooth execution across multiple asset classes while adhering to regulatory compliance and exchange protocols. This integration provides you with secure access to diversified markets, enabling you to take advantage of opportunities across geographies and instruments efficiently.
                  </p>
                  <a href="/" className="readmore">
                    Read More →
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card h-100">
                <img src={SS10} className="card-img-top" alt="IT Consulting & Managed Services" />
                <div className="card-body text-center">
                  <h5 className="card-title">Personalized Support and Training
                  </h5>
                  <div
                    className="underline mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  At CARRIER LAUNCHERS, we prioritize empowering our clients with the knowledge and tools they need to succeed. From onboarding support to advanced training, we provide resources tailored to your trading goals. Our interactive tutorials, live webinars hosted by industry experts, and 24/7 customer support ensure you are never alone in your trading journey. With a focus on client success, we equip you with the expertise and confidence to thrive in the derivatives market.
                  </p>
                  <a href="/" className="readmore">
                    Read More →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="sect bg-f2f2f2 py-5">
        <div className="containera">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect1-title">who
                <span className="text-danger"> Can  </span>Benefit?
              </h2>
              <div
                className="underlinen mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              Our Future and Options Services are designed to cater to a diverse range of clients, including:
            </div>
          </div>

          {/* Features */}
          <div className="row text-center mt-4">
            {/* Feature 1 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-user-tie text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Individual Investors</h5>
                <p className="feature-text">
                Empowering retail traders to make informed decisions and manage portfolios effectively.
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-briefcase text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Institutional Investors</h5>
                <p className="feature-text">
                Providing robust infrastructure and analytics for large-scale operations.
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-globe text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Hedgers</h5>
                <p className="feature-text">
                Enabling businesses to protect against price volatility in commodities and other assets.
                </p>
              </div>
            </div>

            {/* Feature 4 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-clock text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Speculators</h5>
                <p className="feature-text">
                Offering tools to capitalize on market movements with strategic trades.
                </p>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect1-title">why
                <span className="text-danger"> Choose </span>Us?
              </h2>
              <div
                className="underlinen mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              Our Future and Options Services are designed to cater to a diverse range of clients, including:
            </div>
          </div>

            {/* Feature 5 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-chart-line text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Industry Expertise</h5>
                <p className="feature-text">
                With years of experience in financial markets, we understand the intricacies of futures and options trading. Our solutions are built to address real-world challenges and deliver measurable value.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-chart-line text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Technology-Driven Innovation</h5>
                <p className="feature-text">
                We leverage cutting-edge technologies like artificial intelligence (AI), machine learning (ML), and cloud computing to provide fast, secure, and reliable services.
                </p>
              </div>
            </div>
            {/* Feature 6 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-handshake text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Customizable Solutions</h5>
                <p className="feature-text">
                We recognize that every trader has unique needs. That’s why our platforms and tools are fully customizable to suit your trading style and goals.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box p-4">
                <i
                  className="fas fa-handshake text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title">Commitment to Excellence
                </h5>
                <p className="feature-text">
                We are committed to providing an exceptional client experience, from our intuitive platforms to our dedicated customer support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default FO;

