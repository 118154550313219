import React, { useState, useEffect } from 'react';
import '../../../assets/styles/IPO.css';
import BS1 from '../../../assets/images/BS1.jpg'
import BS2 from '../../../assets/images/BS2.jpg';
import BS3 from '../../../assets/images/BS3.jpg';
import BS4 from '../../../assets/images/BS4.jpg';
import BS5 from '../../../assets/images/BS5.jpg';
import BS6 from '../../../assets/images/BS6.jpg';
import BS7 from '../../../assets/images/BS7.jpg';
import BS8 from '../../../assets/images/BS8.jpg';

const IPO = () => {
  const slides = [
    { image: BS1, text: "Launch Your Future with Confidence – Go Public with Carrier Launchers." },
    { image: BS2, text: "Empowering Your IPO Journey, From Start to Success." },
    { image: BS3, text: "Seamless IPO Solutions for a Strong Market Launch." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section1">
        <div className="carousl-container1">
          <div
            className="carousel-slide2 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay1">
              <p className="carousel-txt1">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="bench-staffing">
        <h3 className="section-titl">IPO Services by Carrier Launchers:</h3>
        <hr className="divider2" />
        <div className="contxt1">
          <div className="pic-container1">
            <img src={BS4} alt="Bench" className="animated-pic" />
          </div>
          <div className="textt1">
            <p>
            At Carrier Launchers, we specialize in delivering comprehensive end-to-end IPO services designed to ensure a smooth and successful transition for companies preparing to go public. Our team of experienced professionals provides expert guidance throughout every stage of the IPO process, from initial preparation to post-IPO support, helping businesses access public capital markets with confidence.
            </p>
            <p>
            We begin with a thorough IPO readiness assessment, evaluating your company’s financial and operational structure to ensure compliance with regulatory requirements. Our advisory team works with you to craft a strategic plan that aligns with your long-term growth objectives, positioning your company effectively for investors.
            </p>
            <p>
            Our services extend to financial reporting and compliance, where we assist in preparing your financial statements and navigating the complexities of regulatory frameworks, including SEC filings. We also offer support in capital raising, leveraging our underwriting partners to secure optimal pricing and market placement for your IPO.
            </p>
            <p>
            In addition, we manage investor relations, organizing roadshows and targeting institutional and retail investors to generate interest in your offering. Post-IPO, we provide ongoing support, including market stabilization and compliance management, ensuring a smooth trading experience and long-term investor confidence.
            </p>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect-title"> Our
                Our <span className="text-danger"> IPO Service</span> Offerings:
              </h2>
              <div
                className="underline mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              <p className="sect-subtitle">
                The best services provided by us
              </p>
            </div>
          </div>

          {/* First Quote */}
          <div className="row">
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS5} // Circular Image Placeholder
                    alt="C2C"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Pre-IPO Advisory and Strategy</h3>
                  <h6><strong>IPO Readiness Assessment:</strong></h6>
                  <p className="quote-description">
                  <p className="quote-description">
                  We evaluate your company’s financial and operational structure, identifying any areas of improvement to meet regulatory requirements.
                  </p>
                  </p>
                  <h6><strong>Strategic Planning:</strong></h6>
                  <p className="quote-description">
                  Our experts work closely with your leadership team to define a clear IPO roadmap, focusing on long-term growth and value creation.
                  </p>
                  <h6><strong>Market Positioning:</strong></h6>
                  <p className="quote-description">
                  We assist in developing an attractive investment story that resonates with investors and strengthens your company’s market positioning.
                  </p>
                  <a href="/" className="btnn quote-btn">Learn more--</a>
                </div>
              </div>
            </div>

            {/* Second Quote */}
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS6} // Circular Image Placeholder
                    alt="W2"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Financial Reporting and Compliance</h3>
                  <p className="quote-description">
                    <h6><strong>Audit Preparation:</strong></h6>
                    We help ensure your financial statements meet the highest standards of accuracy and transparency, working alongside auditors for a smooth process.
                  </p>
                  <p className="quote-description">
                    <h6><strong>Regulatory Compliance:</strong></h6>
                    Carrier Launchers ensures full compliance with the necessary regulatory frameworks, such as the SEC regulations and other local and international listing requirements.
                  </p>
                  <a href="/" className="btnn quote-btn">Learn more--</a>
                </div>
              </div>
            </div>

            {/* Third Quote */}
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS8} // Circular Image Placeholder
                    alt="1099"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Underwriting and Capital Raising</h3>
                  <p className="quote-description">
                    <h6><strong>Underwriting Services:</strong></h6>
                    Our investment banking partners assist in the structuring of the IPO, pricing, and placing of shares to maximize value for your company.
                  </p>
                  <p className="quote-description">
                    <h6><strong>Capital Market Strategy:</strong></h6>
                    We devise effective strategies to raise the capital your company needs while aligning with your growth and investment objectives.
                  </p>
                  <a href="/" className="btnn quote-btn">Learn more--</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS7} // Circular Image Placeholder
                    alt="W2"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Investor Relations and Marketing</h3>
                  <p className="quote-description">
                    <h6><strong>Roadshow Management:</strong></h6>
                    We manage your IPO roadshow, helping you effectively communicate with potential investors and market your company’s value proposition.
                  </p>
                  <p className="quote-description">
                    <h6><strong>Investor Targeting and Outreach:</strong></h6>
                    Our team identifies and reaches out to institutional and retail investors, enhancing the overall demand for your IPO.
                  </p>
                  <a href="/" className="btnn quote-btn">Learn more--</a>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS8} // Circular Image Placeholder
                    alt="W2"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Post-IPO Support</h3>
                  <p className="quote-description">
                    <h6><strong>Market Stabilization:</strong></h6>
                    After the IPO, we provide market stabilization services to ensure the smooth trading of your shares and to maintain investor confidence.
                  </p>
                  <p className="quote-description">
                    <h6><strong>Ongoing Compliance:</strong></h6>
                    We assist in ensuring ongoing reporting and compliance, including quarterly filings and governance best practices, enabling you to focus on business growth.
                  </p>
                  <a href="/" className="btnn quote-btn">Learn more--</a>
                </div>
              </div>
            </div>
        </div>
      </section>
      <hr />
      <section>
      <div className="bs1">
        <h3 className="header">Why Choose Carrier Launchers?</h3>
        <ol className="paragraph-list">
          <li>
            <h4 className="paragraph-heading">Expertise:</h4>
            <p>
            With years of experience and a proven track record, we have helped numerous companies navigate the complexities of the IPO process.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Tailored Approach:</h4>
            <p>
            We understand that every company’s journey to going public is unique, and we provide bespoke solutions that match your specific needs.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">End-to-End Support:</h4>
            <p>
              From pre-IPO planning to post-IPO market stabilization, our team supports you through every phase of the process.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Global Reach:</h4>
            <p>
            Carrier Launchers has strong global ties with institutional investors, underwriters, and market influencers, giving your IPO the exposure it deserves.
            </p>
          </li>
        </ol>
        <p>
        Choose Carrier Launchers for our expertise, tailored approach, and end-to-end support throughout the IPO process. With years of experience, we offer customized solutions that meet your unique needs, ensuring a successful transition to the public market. Our global network ensures your IPO receives the exposure it deserves.
        </p>
      </div>
    </section>
    <hr/>
      <section className="appointment">
        <div className="container">
          <div className="fact">
            <h2 className="fact-heading">Start Your IPO Journey Today</h2>
            <p className="fact-description">
            Reach out to us today to discuss how we can support your company in going public and achieving long-term growth.
            </p>
            <a href="/" className="btn-appointment">Get Started Now</a> {/* Action Button */}
          </div>
        </div>
      </section>

    </>

  )
}

export default IPO;
