// src/components/Home.js

import React from 'react';
import "../../assets/styles/Home.css"; // Adjust the path according to your project
import 'bootstrap/dist/css/bootstrap.min.css';
import Purpose from "../../assets/images/purpose.webp"; // Adjust path to image
import S1 from "../../assets/images/S1.jpg";
import S2 from "../../assets/images/S2.jpeg";
import S3 from "../../assets/images/S3.webp";
import S4 from "../../assets/images/S4.webp";
import S5 from "../../assets/images/S5.jpg";
import S6 from "../../assets/images/S6.webp";
import S7 from "../../assets/images/S7.avif";
import S8 from "../../assets/images/S8.avif";
import S9 from "../../assets/images/S9.avif";
import S10 from '../../assets/images/S10.jpg';
import S11 from '../../assets/images/S11.avif';
import S12 from '../../assets/images/S12.jpg';
import S13 from '../../assets/images/S13.jpg';
import S14 from '../../assets/images/S14.avif';
import S15 from '../../assets/images/S15.avif';
import S16 from '../../assets/images/S16.jpg';
//import Join from "../../assets/images/Join.webp";
const caseStudies = [
  {
    client: "Case Study: 1",
    industry: "Financial Services and Investment",
    challenges: "Lack of personalized investment strategies, high-risk exposure in equity markets, and difficulty in tracking market trends.",
    solutions: "Implemented a tailored portfolio management service (PMS), provided investment advisory based on in-depth market research and technical analysis, and offered personalized stock recommendations. Introduced real-time trading platforms for seamless access to financial data and trading options.",
    results: "Achieved a 15% annual return on the client’s portfolio, reduced risk exposure by 25% through diversified strategies, and increased client confidence in long-term investment decisions.",
    takeaways: "Personalized financial advisory and real-time trading solutions can significantly enhance investor outcomes while mitigating risk. The integration of market insights and modern tools helps clients stay ahead of market trends."
  },
  {
    client: "Case Study: 2",
    industry: "Banking and Financial Services",
    challenges: "Difficulty in accessing IPO opportunities, lack of understanding of the IPO process, and limited real-time data for timely decision-making.",
    solutions: "Provided comprehensive IPO advisory services, including stock recommendations, documentation assistance, and allotment tracking. Offered a user-friendly online trading platform with real-time IPO updates.",
    results: "Client successfully participated in 3 IPOs, resulting in a 30% profit within six months of listing. Enhanced understanding of the IPO process led to better decision-making and increased participation in future IPOs.",
    takeaways: "Expert IPO advisory and real-time access to market data provide investors with the tools to capitalize on emerging opportunities, leading to substantial gains."
  },
  {
    client: "Case Study: 3",
    industry: " Investment and Asset Management",
    challenges: " Limited access to information for stock selection, inability to track and assess market trends effectively.",
    solutions: "Provided detailed technical and fundamental analysis reports, along with real-time stock recommendations. Delivered interactive tools for tracking stock performance and market trends.",
    results: " Increased the client’s stock selection accuracy by 20%, leading to better portfolio performance. Client reported a higher level of satisfaction due to increased awareness and clarity on investment decisions.",
    takeaways: "Access to advanced analysis tools and expert insights is critical for informed stock selection and long-term portfolio growth."
  },
  {
    client: "Case Study: 4",
    industry: "Financial Services",
    challenges: "Complexity in managing multiple investment vehicles such as stocks, mutual funds, and options in a single platform.",
    solutions: "Delivered a unified online platform offering real-time access to stocks, mutual funds, and options. Provided comprehensive F&O services with risk management tools and educational resources for better decision-making.",
    results: "Client was able to manage a more diversified portfolio with ease, leading to a 40% increase in trading volume and enhanced returns.",
    takeaways: "Technology-driven platforms enhance the user experience by streamlining access to multiple financial products, fostering better investment management."
  }
];

const images = [
  { id: 1, src: S11, alt: 'Member1' },
  { id: 2, src: S12, alt: 'Member2' },
  { id: 3, src: S13, alt: 'Member3' },
  { id: 4, src: S14, alt: 'Member4' },
  { id: 5, src: S15, alt: 'Member5' },
  { id: 6, src: S16, alt: 'Member6' },
];

const TestimonialCard = ({ imageSrc, quote, linkText, name, title }) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-content">
        <img src={imageSrc} alt={`${name}'s profile`} className="profile-image" />
        <div className="quote-section">
          <div className="quote-icon">“</div>
          <p className="quote">{quote}</p>
          <a href="/about" className="link">{linkText}</a>
          <div className="author-info">
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <div className="home">
        <section className="home-content-section">
          <div className="home-content">
            <h2><em>--"Empowering Your Investments, Shaping Your Future."--</em></h2>
            <p>
              <i>
                At Carrier Launchers, we provide a comprehensive suite of services that empower individuals and businesses in their financial journeys. Specializing in share market investments, mutual funds, F&O trading, and IPO insights, we offer expert guidance to help clients make informed decisions. Our real-time stock market news ensures you stay ahead of the curve, while our non-IT services, including financial consulting and exceptional customer support, cater to all your financial needs. With years of experience and a client-centric approach, we aim to foster financial growth and success for every individual we serve.
              </i>
            </p>
          </div>
        </section>

        {/* Background Image Scroll Section */}
        <div className="scrollable-background">
          <div className="scrollable-background-image"></div>
          <div className="scrollable-background-image"></div>
          <div className="scrollable-background-image"></div>
        </div>
      </div>
      <hr />
      <section>
        <div className="mission-section">
          <div className="container1">
            <div className="mission-content">
              <h2 className="mission-heading">Our Mission</h2>
              <p className="mission-description">
                At Carrier Launchers, our mission is to provide innovative and reliable financial services, guiding individuals and businesses through the dynamic world of share markets, mutual funds, F&O trading, IPOs, and stock market news. We aim to empower clients with the tools, knowledge, and insights needed to make informed financial decisions. In addition to our financial services, we offer expert non-IT solutions, including financial consulting and customer support, tailored to meet the unique needs of each client. Our commitment is to deliver high-quality, personalized services that foster long-term growth and success for every individual and organization we serve.
              </p>
            </div>
            <div className="mission-image">
              <img src={S9} alt="Mission" />
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-f2f2f2 py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-1 text-center">
              <h2 className="section-title">
                <span className="text-danger">Our </span>Capabilities
              </h2>
              <div
                className="underline d-block mx-auto mb-2"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>

              <p className="section-subtitle">
                The best services provided by us
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S1} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Share Market Services</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                    At Carrier Launchers, we offer comprehensive Share Market Services including Equity Trading, Portfolio Management Services (PMS), and Investment Advisory. Our expert team provides in-depth Stock Market Research, Technical & Fundamental Analysis, and IPO Advisory. We also offer intuitive Trading Platforms for seamless, real-time stock trading.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S2} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Mutual Fund Services</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our Mutual Fund Services offer expert advisory, direct investments, and portfolio management tailored to your financial goals. We assist with selecting the best funds based on risk and objectives, facilitate Systematic Investment Plans (SIPs) for steady growth, and ensure ongoing portfolio monitoring for optimal returns in changing market conditions.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S3} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">F&O (Futures and Options) Services</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our F&O (Futures and Options) Services provide advanced trading strategies for investors to manage risk and capitalize on market movements. We offer expert advice on Futures and Options contracts, technical analysis, and customized strategies. Our real-time data and risk management tools empower investors to make informed decisions for optimized returns.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S4} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">IPO Related Services</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our IPO Related Services provide expert guidance in IPO investments, from advisory on the best opportunities to application support and tracking allotments. We assist with documentation, ensuring a seamless IPO process, and offer market insights to help clients make informed decisions, aiming for optimal returns in the IPO market.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S5} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Financial Consulting</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                    We provides expert financial consulting services to help businesses optimize
                    their financial strategies. Our team offers tailored solutions in areas like
                    budgeting, forecasting, risk management, and investment planning, ensuring clients
                    make informed decisions to achieve long-term financial success and stability.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S6} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Investment Education</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our Investment Education services provide clients with essential knowledge to navigate financial markets. Through workshops, seminars, online courses, and webinars, we offer practical insights into trading, portfolio management, and market analysis. Additionally, we provide educational resources and investor awareness campaigns to help clients make informed, confident investment decisions.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S7} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Customer Support and Call Center Services</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our Customer Support and Call Center Services offer comprehensive, responsive solutions to address client needs. We provide 24/7 assistance, including handling inquiries, troubleshooting issues, managing transactions, and offering product or service-related guidance. By delivering personalized support, we aim to enhance customer satisfaction, build trust, and resolve issues promptly, ensuring a seamless experience for every client.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={S8} className="card-img-top" alt="Service" />
                <div className="card-body text-center">
                  <h5 className="card-title">Technology-Driven Trading Solutions</h5>
                  <div
                    className="underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="card-text">
                  Our Technology-Driven Trading Solutions offer clients advanced, cutting-edge tools to trade efficiently in the stock market. With real-time data, automated trading algorithms, AI-based market analysis, and easy-to-use platforms, we empower investors to make informed decisions. These solutions ensure faster execution, greater accuracy, and enhanced risk management, providing a competitive edge for traders in dynamic market conditions.
                  </p>
                  <a href="/" className="readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            {/* You can duplicate this col-md-3 for additional cards */}
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="purpose">
          <h3>Our Purpose</h3>
          <p>"Guiding Investors to Financial Success through Innovation and Support"</p>
          <div>
            <TestimonialCard
              imageSrc={Purpose}
              quote="At Carrier Launchers, Our purpose is to empower investors by providing them with comprehensive financial services, innovative market solutions, and expert guidance. We aim to create a seamless, technology-driven investment experience that promotes financial growth, security, and education. Through personalized strategies, market insights, and customer support, we ensure that clients achieve their investment goals with confidence and clarity."
              linkText="More about us →"
            />
          </div>
        </div>
      </section>
      <hr />
      <section>

        <div className="mission-wrapper">
          <div className="mission-container">
            <div className="mission-img-wrapper">
              <img src={S10} alt="Mission" />
            </div>
            <div className="mission-text-wrapper">
              <h2 className="mission-title">Case Studies or Success Stories:</h2>
              <p className="mission-paragraph">
              At Carrier Launchers, we offer a wide range of services to empower clients in the financial markets. Our offerings include equity trading, portfolio management, mutual fund investment strategies, IPO advisory, F&O trading, financial consulting, and comprehensive customer support. We provide personalized solutions designed to help clients maximize returns, mitigate risks, and make informed investment decisions. With cutting-edge technology, market insights, and educational resources, we guide clients towards achieving their financial goals. Our approach ensures seamless access to financial markets and equips clients with the tools and knowledge necessary for long-term success and stability in their investment journey.

              </p>
              <button className="mission-action-btn" onClick={() => console.log("Learn More clicked!")}>
                Learn More ---
              </button>
            </div>
          </div>
          <hr />
          <div className="col-md-12 mb-1 text-center">
            <h2 className="section-title">Our Proven
              <span className="text-danger"> Case Studies </span>:
            </h2>
            <div
              className="underline d-block mx-auto mb-2"
              style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
            ></div>

            <p className="section-subtitle">
            At Carrier Launchers, we specialize in providing innovative, technology-driven solutions in the financial sector. Through our comprehensive services in equity trading, portfolio management, IPO advisory, and more, we empower clients to reach their investment goals with confidence. Here’s a glimpse into some of our successful case studies:
            </p>
          </div>
        </div>
        <section className="case-studies-section">
          <div className="case-studies-container">
            {caseStudies.map((study, index) => (
              <div className="case-study-card" key={index}>
                <h3>Client: {study.client}</h3>
                <table>
                  <tbody>
                    <tr>
                      <td><strong>Industry</strong></td>
                      <td>{study.industry}</td>
                    </tr>
                    <tr>
                      <td><strong>Challenges</strong></td>
                      <td>{study.challenges}</td>
                    </tr>
                    <tr>
                      <td><strong>Solutions Provided</strong></td>
                      <td>{study.solutions}</td>
                    </tr>
                    <tr>
                      <td><strong>Results Achieved</strong></td>
                      <td>{study.results}</td>
                    </tr>
                    <tr>
                      <td><strong>Key Takeaways</strong></td>
                      <td>{study.takeaways}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </section>
      </section>
      <hr />
      <p className='conclusion'>our comprehensive range of services is designed to meet the diverse needs of investors, helping them navigate the complexities of the financial markets. From equity trading and portfolio management to mutual funds, F&O trading, IPO advisory, and wealth management, we provide expert guidance, personalized solutions, and innovative tools to maximize returns and minimize risks. Our advanced technology-driven trading platforms and real-time market insights ensure seamless and informed decision-making for clients. Additionally, we offer taxation and financial planning advice, risk management strategies, and investment education, empowering investors to build long-term wealth. With a focus on transparency, integrity, and customer satisfaction, we are committed to supporting clients at every step of their investment journey, ensuring they achieve their financial goals with confidence.</p>
      <hr />
      <section>
        <div className="col-md-12 mb-1 text-center">
          <h2 className="section-title">Our
            <span className="text-danger"> Memories </span>, Our Journey
          </h2>
          <div
            className="underline d-block mx-auto mb-2"
            style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
          ></div>
          <br></br>
        </div>
        <div className="gallery-container">
          <div className="gallery-row">
            {images.map((image) => (
              <div key={image.id} className="gallery-column">
                <div className="gallery-image-card">
                  <img src={image.src} alt={image.alt} className="gallery-image" />
                  <div className="image-caption">
                    <h5 className="image-alt">{image.alt}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <hr/>
      <section className="appointmentn">
      <div className="container">
        <div className="fact">
          <h2 className="fact-heading">Book an Appointment</h2>
          <p className="fact-description">
            Schedule an appointment with our team to discuss your business needs, explore our services, 
            and discover solutions that can take your business to the next level.
          </p>
          <a href="/" className="btn-appointment">Book Now</a> {/* Action Button */}
        </div>
      </div>
    </section>

    </>
  );
};

export default Home;
