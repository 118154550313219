import React, { useState, useEffect } from 'react';
import '../../../assets/styles/ShareMarketServices.css'; // Ensure his path is correct
import SD1 from '../../../assets/images/SD1.jpeg';
import SD2 from '../../../assets/images/SD2.jpeg';
import SD3 from '../../../assets/images/SD3.jpg';
import SD4 from '../../../assets/images/SD4.jpeg';



const ShareMarketServices = () => {
  const slides = [
    { image: SD1, text: "Your Gateway to Financial Success." },
    { image: SD2, text: "Chart Your Financial Growth with Us." },
    { image: SD3, text: "Reaching New Heights in Share Market Investments." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  return (
    <>
      {/* Carousel Section */}
      <section className="carousel-section">
        <div className="carousel-container">
          <div
            className="carousel-slide zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="text-overlay">
              <p className="carousel-text">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>

      <hr />
      <section className="sms">
        <h3 className="section-titles">About Our Share Market Services:</h3>
        <hr className="divider1s" />
        <div className="contxts">
          <div className="pic-containers">
            <img src={SD4} alt="sms" className="animated-pics" />
          </div>
          <div className="textts">
            <p>
            Our Share Market Services offer a comprehensive suite of solutions designed to help investors navigate the complexities of the financial markets. From Equity Trading, which enables seamless buying and selling of stocks on exchanges like NSE and BSE, to Portfolio Management Services (PMS), where professional managers tailor investment strategies to maximize returns, we ensure that every investor's financial goals are met. We provide expert Investment Advisory and in-depth Stock Market Research, backed by both Technical and Fundamental Analysis, to help clients make informed, data-driven decisions. Our IPO Advisory and Trading Platforms ensure investors have the tools and knowledge needed for successful market participation, while also offering personalized recommendations and tracking for IPO investments.
            </p>
            <p>
            Whether you're a retail investor or an institutional client, our services are designed to cater to diverse needs with the utmost professionalism. We specialize in offering strategic insights and tailored approaches to Futures & Options (F&O) Trading, including risk management, margin funding, and complex multi-leg strategies. In addition, we provide Taxation Advisory and Wealth Management services to optimize investment returns while minimizing tax liabilities. Our platforms and resources are designed to give clients real-time access to the markets, robust research tools, and a full spectrum of services to build and manage wealth effectively in the ever-evolving share market landscape.
            </p>
            <a href="/" className="rdm">
              read more--
            </a>
          </div>
        </div>
      </section>
      <hr />
      {/* Left and Right Section */}
      <div className="custom-software-container">
        {/* Right Side Content */}
        <div className="why-txt">
          <h3>Why Choose Our Share Market Services?</h3>
          <hr />
          <ul>
            <li>
              <strong>1.Expert Guidance:</strong><br />Our team of analysts and portfolio managers brings years of experience in the financial markets, offering clients insightful research, recommendations, and strategies to enhance portfolio performance.
            </li>
            <li>
              <strong>2.Cutting-Edge Technology:</strong><br /> We provide access to sophisticated trading platforms with real-time market data, technical tools, and research reports to help clients execute trades efficiently.
            </li>
            <li>
              <strong>3.Personalized Approach:</strong><br /> We understand that each investor has unique goals, risk tolerance, and preferences. Our services are tailored to fit individual needs, from equity trading to comprehensive wealth management.
            </li>
            <li>
              <strong>4.Comprehensive Support:</strong>
              <br /> Receive end-to-end support throughout your investment journey, from setting up trading accounts to tax planning and portfolio diversification, ensuring all aspects of your financial strategy are covered.
            </li>
            <li>
              <strong>5.Risk Management Strategies:</strong><br />Our services include tailored risk management strategies to help mitigate potential losses, ensuring your investments are well-protected during market fluctuations.
            </li>
            <li>
              <strong>6.Diversified Investment Options:</strong><br />We offer a wide range of investment opportunities across different asset classes, helping you build a diversified portfolio that aligns with your financial objectives.
            </li>
            <li>
              <strong>7.Timely Market Insights:</strong><br />Stay ahead of market trends with our timely and relevant market insights, giving you an edge in identifying investment opportunities and making data-driven decisions.
            </li>
            <li>
              <strong>8.Client-Centric Approach:</strong><br />We prioritize your financial success by fostering long-term relationships and offering continuous support, ensuring that our services evolve with your changing financial goals and needs.
            </li>
          </ul>
        </div>
      </div>
      <section className="sect1 bg-f2f2f2 py-5">
        <div className="containera">
          {/* Section Title */}
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect1-title">
                <span className="text-danger">Who </span> We Serve?
              </h2>
              <div
                className="underlines mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
            </div>
          </div>

          {/* Features */}
          <div className="row text-center mt-4">
            {/* Feature 1 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box1 p-4">
                <i
                  className="fas1 fa-user-tie text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title1">Beginners</h5>
                <p className="feature-text1">
                We simplify share market investing for beginners with easy-to-follow guides and personalized one-on-one coaching. From understanding stock basics to making your first trade, we provide the knowledge and support you need to start confidently. Our resources ensure a smooth introduction to investing, empowering you to build a strong financial future.
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box1 p-4">
                <i
                  className="fas1 fa-briefcase text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title1">Seasoned Investors</h5>
                <p className="feature-text1">
                We empower seasoned investors to refine their strategies with advanced analytics and exclusive market insights. Gain access to in-depth research, trend analysis, and portfolio optimization tools designed to maximize returns and minimize risks. Stay ahead in the market with our expert guidance tailored to support your long-term investment goals.
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="feature-box1 p-4">
                <i
                  className="fas1 fa-globe text-danger mb-3"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h5 className="feature-title1">Corporate Clients</h5>
                <p className="feature-text1">
                We assist corporate clients in achieving financial growth through tailored investment strategies and portfolio diversification. Our expert guidance ensures risk is managed effectively while optimizing returns across equity, fixed income, and alternative assets. Partner with us to align your corporate investments with long-term business objectives and market opportunities.
                </p>
              </div>
            </div>

            {/* Feature 4 */}
           </div>
        </div>
      </section>
      <section className="appointmentd">
        <div className="container">
          <div className="fact">
            <h2 className="fact-heading">Connect with us today!</h2>
            <p className="fact-description">
            Start building your financial future with confidence. 
            </p>
            <a href="/" className="btn-appointment">Get Started Today</a> {/* Action Button */}
          </div>
        </div>
      </section>

    </>
  );
};

export default ShareMarketServices;
